// ATS
//////

#myaladom-container.myaladom-container-ats{
  max-width: 100%;
  padding-left: 2rem ;
  padding-right: 2rem;
}

#myaladom-ats, #myaladom-ats-detail, #myaladom-candidacy-mails-templates {
  color: $black;
  h2 {
    color: $black;
  }
  .btn-ats, .b-dropdown {
    border-radius: 3px;
    box-shadow: $default-shadow;
  }
  .b-dropdown {
    button {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.dropdown-toggle-no-caret {
        padding: 0;

        &::before, &::after {
          display: none;
        }
      }
    }
  }

  .dropdown ul {
    min-width: 100%;
  }

  .custom-checkbox {
    margin: 0;

    .custom-control-label {
      &::after, &::before {
        width: 1rem;
        height: 1rem;
        top: .2rem;
        left: -1.5rem;
      }
    }
  }

  .ats-board-view-toggle {
    &-input {
      font-weight: 500;

      .custom-switch {
        margin-left: 2.5rem;
        padding: 0;
        .custom-control-label::before, .custom-control-label::after {
          border-radius: .5rem;
          margin-top: .2rem;
          cursor: pointer;
        }

        .custom-control-label::before {
          width: 1.75rem;
          height: 1rem;
          pointer-events: all;
        }

        .custom-control-label::after {
          top: calc(.2rem - 3px);
          transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }
      }
    }
  }

  .btn-ats-blue {
    @extend .btn-ats;
    color: $white;
    background: #2653fb;
  }

  .btn-ats-white {
    @extend .btn-ats;
    color: $black;
    background: $white;
    max-width: unset;
    width: 100%;
  }

  .btn-ats-light-gray {
    @extend .btn-ats;
    color: $black;
    background: $gray-200;
  }

  .btn-ats-dark-blue {
    @extend .btn-ats;
    color: $white;
    background: $black;
  }
  .btn-ximi {
    border: 1px solid gray;
    border-radius: 25px;
    box-shadow: rgb(50 50 93 / 25%) 0 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
    padding: 1rem;
    &:hover {
      box-shadow: unset;
    }
    .ximi-logo {
      float: left;
      width: 30%;
    }
    .btn-text-ximi {
      font-size: 1.2rem;
      margin: 0 auto;
      padding: 0.2rem;
    }
  }


  input[type=checkbox] {
    box-shadow: $default-shadow;
  }

  .checkbox-block {
      display: flex;
      align-items: center;
      float: left;
  }

  .ats-status-filter-mobile-block, .ats-status-filter-block {
    .ats-status-filter {
      padding: 3px;
      width: 100%;

      &>div.btn-group-toggle{
          width: 100%;
      }

      .btn-ats-status-filter {

        &.status-recruited {
          background-color: $green;
          color: $white;
        }

        &.active {
          background-color: #2653FC;
          color: white;
        }
        &:hover {
          background-color: $gray-600;
        }

        .nb-candicies-badge {
          font-size: 14px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: $light-red 0% 0% no-repeat padding-box;
          color: $white;
          border: 1px solid $white;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          position: absolute;
          top: -5px;
          right: 10px;
        }
      }
    }
  }

  .ats-status-filter-mobile-block {
    @include media-breakpoint-up(md) {
      display: none;
    }
    .ats-status-filter {
      margin-bottom: 8px;
      max-height: 150px;

      &.col-4 {
        height: 33vw;

        .btn-ats-status-filter {
          height: 33vw;
        }
      }

      .btn-ats-status-filter {
        width: 100%;
        color: $black;
        background-color: $white;
        border-radius: 5px;
        box-shadow: $default-shadow;
        font-size: 17px;
        padding: 10px 12px;
        min-height: 50px;
        max-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

  .ats-status-filter-block {

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .ats-status-filter {

      .btn-ats-status-filter {
        width: 100%;
        margin: 5px;
        color: $black;
        background: $gray-200;
        border-radius: 10px;
        font-size: 17px;
        padding: 10px 12px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.multi_words {
          padding: 10px 8px;
          @media (min-width: 1795px) {
            font-size: 17px;
          }
          @media (min-width: 1705px) {
            font-size: 16px;
          }
          @media (min-width: 1610px) {
            font-size: 15px;
          }
          @media (min-width: 1525px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .ats-action-block {
    color: $black;
    padding: 10px 0;

    .row {
      width: 100%;
    }

    i {
      color: $black;
    }

    .toggle-ats-switch, .toggle-ats-sort {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 46px;
      height: 46px;

      i{
        color: $white;
      }
      span {
        background-color: $white;
      }
      background-color: $black;
      border-radius: 8px;
      box-shadow: $default-shadow;
      cursor: pointer;

      &:hover {
        i{
          color: $black;
        }
        span {
          background-color: $black;
        }
        background-color: $white;
        border: $black 2px solid;
      }
    }

    .ats-switch-view-block {
      .custom-control-label {
        position: absolute;
        top: -40px;
        left: 24px;

        &::after, &::before {
          margin-left: 9px;
        }
      }

      & > div {
        display: flex;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    #ats-detail-sort-input {
      height: 100%;
      .dropdown-toggle::after {
        display: unset !important;
      }
    }

    .ats-filter-prefix-icon {
      font-size: 18px !important;
      font-weight: bold;
    }

    .ats-action-dropdown {
      height: 100%;
      width: 160px;
      @include media-breakpoint-down(md) {
        width: 130px;
      }
      button {
        text-align: left;
        border-radius: 500px;
        height: 100%;
        background-color: $black;
        color: $white;
        width: 100%;
        .checkbox-block {
          float: left;
          .custom-checkbox {
            padding-left: 1.5rem;
          }
        }

        &::after {
          top: 30%;
        }
      }
    }
  }

  .ats-action-block {

    .ats-filter {
      transition: $transition-time ease-in-out;
      opacity: 1;
      visibility: visible;

      i.ats-filter-clear, i.ala-loading-clock-o {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        cursor: pointer;
        border-radius: 50%;
        padding: 7px;

        &:hover {
          box-shadow: $default-shadow;
        }
      }

      i {
        z-index: 1;
      }

      i.ats-filter-prefix-icon {
        font-size: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        border-radius: 50%;
        padding: 5px;
      }
      i.ats-filter-suffix-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        left: unset;
        padding: 5px;
      }
    }
    .ats-filter-dropdown, .ats-filter-show-more {
      margin-bottom: 6px;
      width: 100%;
      button, select:not(.monthselect), .form-control.reportrange-text, input:not(.yearselect) {
        border-radius: 500px !important;
        font-size: 15px;
        font-weight: normal;
        width: 100%;
        height: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        border: none;
        color: $black;
        &:not(.reportrange-text) {
          box-shadow: $default-shadow;
        }

        &.ats_restricted {
          border-radius: 0!important;
          margin-left: 2.5rem;
          padding-left: 1.5rem;
        }

        &.disabled {
          cursor: unset;
        }
      }
    }

    .ats-filter-dropdown {

      .reportrange-text {
        box-shadow: none;
        text-align: left;
        vertical-align: center;
      }

      .dropdown {
        width: 100%;
        box-shadow: none;

         &:not(#ats-detail-sort-input) > button, .reportrange-text {
          box-shadow: none;
          text-align: left;
          vertical-align: center;
        }

        .dropdown-toggle::after {
          display: none;
        }

        .dropdown-menu {
          color: blue;
          .custom-radio {
            width: 100%;
            padding: 0;
            margin: 0;
            color: red;
            label {
              width: 100%;
              &::before, &::after {
                display: none;
              }
            }
          }
        }
      }

      select {
        option:first-child{color: grey; display: none;}
      }

      :not(#ats-detail-sort-input) {
        .dropdown-toggle::before, select::after, .reportrange-text::before {
          display: inline-block;
          margin-left: .255em;
          margin-right: .255em;
          content: "";
          width: 10px;
          height: 10px;
        }

        select::after {
          vertical-align: .255em;
          border-bottom: 2px solid $black;
          border-right: 2px solid $black;
          transform: rotate(45deg);
        }

        .dropdown-toggle::before, .reportrange-text::before {
          transform: rotate(135deg);
          border-top: 0;
          border-bottom: 1px solid $black;
          border-right: 1px solid $black;
        }
      }
    }
  }

  .ats-add-candidacy-block {

    width: fit-content;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 45px;
      text-decoration: none;
      background-color: $black;

      i {
        font-size: 19px;
        color: $white;
      }
    }
  }

  #ats-list-block {

    figure {
      align-items: center;
      width: 60px;
      height: 60px;
      border: none;
    }

    .ats_check_all {
      width: 100%;
      text-align: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .ats-candidacies-card-no-result {
      text-align: center;
      margin-top: 5%;
      color: $black;
      font-size: 15px;
    }

    .ats-candidacies-card {
      display: flex;
      font-size: 15px;
      color: $black;
      background: $white 0 0 no-repeat padding-box;
      transition: color $transition-short-time ease-in-out, background-color $transition-short-time ease-in-out;
      box-shadow: 0 5px 6px #00000029;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 10px 10px 10px 0;
      cursor: pointer;

      &.checked {
        background-color: $black;
        color: $white;
      }

      @include media-breakpoint-down(md) {
        display: block;
        .checkbox-block {
          align-items: normal;
        }
      }

      .row {
        width: 100%;
        margin-left: 10px;

        &>div {
          margin-bottom: 5px;
        }
      }

      &:hover {
        box-shadow: inset 0px 1px 6px #000000BA;
      }

      &-email {
        word-wrap: break-word;
      }

      &-job {
        font-weight: 500;
      }

      &-city {
        font-size: 17px;
        font-weight: 600;

        i {
          color: $light-red;
        }
      }

      &-origin, &-already-applied {
        font-size: 0.75rem;
        font-weight: bold;
      }


      &-already-applied {
        color: $red;
      }

      table {
        width: 100%;
      }
    }
  }

  .dropdown-menu {
    box-shadow: $default-shadow;
    border-radius: 10px;
  }

  #attachment-input .dropdown-menu {
    max-width: 100%;
    overflow-wrap: break-word;

    .dropdown-item {
      white-space: unset;
    }

    .ats_restricted {
      margin-right: 1rem;
    }

    a.delete-attachment {
      color: #999;
      &:hover {
        color: black;
        text-decoration: none;
      }
    }
  }
  .dropdown-item {
    cursor: pointer;
  }

  .ats-attachements-modal-title-block, .ats-mail-modal-title-block, .ats-sms-modal-title-block, .ats-tag-modal-title-block, .ats-modal-title-block {
    i {
      font-size: 60px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      margin-left: 5%;
    }
  }

  .ats-sms-form {
    textarea {
      width: 100%;
      min-height: 100px;
      border-radius: 10px;
      border: 1px solid $black;

      &.invalid {
        border-color: $light-red;
      }
    }
  }

  .ats-attachements-modal-title-block {
    &-content {
      i {
        font-size: inherit;
      }
    }
  }

  .btn-ats-upgrade {
    display: flex;
    width: 100%;
    background: $blue-gradient-reverse !important;
    color: $white;
    i {
      font-size: 1.5rem;
      color: white;
    }
    font-weight: bold;
    justify-content: center;
    align-items: center;
  }

}

#myaladom-ats {

  .ats-title-block {
    margin: 2%;
  }

  .bubble-rating {
    display: block;
    max-width: 105px;
  }

  #ats-board-block {
    display: flex;
    flex-flow: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 10px;
    @extend .custom_scroll_bar;

    .status-list {
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: auto;
      flex: 1 1 auto;
      border: 1px solid $gray-200;
      border-radius: 5px;
      min-height: 200px;
      min-width: 280px;
      max-width: 280px;
      margin: .5rem;
      //padding-bottom: .5rem;
      background-color: $gray-100;
      box-shadow: $default-shadow;

      & > div {
        height: 100%;
      }

      &.collapse-close {
        height: 260px;
        flex-direction: row;
        border: 2px solid gray;
        width: 50px;
        min-width: 50px;
        transition: width 0.6s ease-in-out;
      }

      &:not(.collapse-close) {
        transition: width 0.6s ease-in-out;
      }

      .id-status {
        display: flex;
        flex-flow: column;
        height: 100%;

        .overlay-wrapper {
          display: flex;
          flex-direction: column;
        }

        .list-group {
          flex-grow: 1;
          max-height: 800px;
          height: 100%;
          overflow-y: auto;
          padding: 0 .5rem;
          @extend .custom_scroll_bar;
        }
      }

      div.list-group-item.card {
        padding: 1rem 0.5rem !important;
        border-radius: 5px;
        width: 100%;
        box-shadow: $default-shadow;

        &.checked {
          background-color: $black;
          color: $white;
        }

        .nb-candicies-badge {
          font-size: 12px;
          display: none;
          align-items: center;
          justify-content: center;
          background: $electric-blue 0% 0% no-repeat padding-box;
          color: $white;
          border: 1px solid $white;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          right: 0px;
          transform: translateY(-50%);
        }
      }
    }

    div.candidacy-content {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .candidacy-candidate-name {
        font-size: 1rem;
        font-weight: 500;
      }

      .candidacy-title, .candidacy-city, .candidacy-title, .candidacy-rate {
        margin-bottom: 0.5rem;
      }

      .checkbox-block {
        .custom-checkbox {
          padding-left: 1.8rem;
          input[type=checkbox] {
            float: left;
          }
        }

      }
    }

    .vertical-title {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      font-weight: bold;
      color: $black;
      font-size: .7rem;
      margin: 0;
      z-index: 15;
      text-transform: uppercase;
    }
    .status-list-click {
      font-size: 1rem;
    }

    .candidacies-board-count {
      writing-mode: horizontal-tb;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: $electric-blue 0% 0% no-repeat padding-box;
      color: $white;
      border: 1px solid $white;
      min-width: 30px;
      height: 30px;
      padding: 0 5px;
      border-radius: 15px;
      position: absolute;
      top:0;
      right: 50%;
      transform: translate(50%,-50%);
    }

    button.open-close-status-list {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      border-radius: 25px;
      margin: 1rem;
      z-index: 100;
      &.white-icon {
        color: $white;
      }
      /*&:hover {
        background-color: $gray-400;
      }*/
    }
    .drag-drop-card {
      border-radius: 15px;
      margin: 0.5rem 0 0 !important;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: .5rem !important;
      }
    }

    .status-title-box {
      border: 2px solid gray;
      border-radius: 5px;
      height: 100px;
      background-color: $gray-100;
      display: flex;
      justify-content: space-between;

      .status-title {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        color: $black;
        font-size: .7rem;
      }

      .button.open-close-status-list {
        margin: 1rem 0 1rem 1rem;
      }

    }
    .refused-title-box, .archived-title-box, .recruited-title-box {
      color: $white;
      border: unset;
      .status-title, .vertical-title {
        color: $white;
      }
    }
    .recruited-title-box {
      background-color: $green;
    }

    button.more-candidate {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      margin-top: 1rem;
      i {
        font-size: 1.25rem;
      }

    }
  }
}

#myaladom-ats-detail {

  textarea::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 5px;
    margin: 3px 4px;
    background-color: #E7E7E8;
  }

  #status-input {
    max-width: 250px;
    min-height: 36px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 5%;
    }
  }

  textarea::-webkit-scrollbar {
    width: 9px;
  }

  textarea::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: $black;
  }

  color: $black;

  hr {
    border: 2px solid $black;
  }

  .ats-detail-main-infos {
    margin-top: 20px;
    figure {
      align-items: center;
      border-radius: 50%;
      border: 3px solid $black;
      width: 150px;
      height: 150px;

      @media (max-width: 1500px) {
        width: 100px;
        height: 100px;
      }
      @media (max-width: 1200px) {
        width: 150px;
        height: 150px;
      }
      @media (max-width: 1000px) {
        width: 120px;
        height: 120px;
      }
    }
    &-name {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &-rate{
      display: inline-block;
      margin-bottom: 5px;
      width: 100px;
    }
    &-city {
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 10px;

      i {
          color: $light-red;
      }
    }
    &-menu {
      float: right;
      &-icon {
        width: 36px;
        height: 36px;
        line-height: 24px;
        font-size: 24px;
        text-align: center;
        vertical-align: center;
        cursor: pointer;
        border-radius: 3px;
        color: $white;
        background-color: $testimony-blue;

        &:hover {
          color: #FFFFFF50
        }
      }
    }
    &-email, &-phone {
      font-size: 17px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      .btn {
        width: 24px;
        height: 24px;
        line-height: 24p;
        display: flex;
        justify-content: center;
        align-items: center;

        &.edit_contact_validate {
          &:hover {
            background-color: $success;
          }
        }

        &.edit_contact_cancel {
          &:hover {
            background-color: $danger;
          }
        }
      }
    }

    &-origin {
      font-size: 1.25rem;
      font-weight: bold;
    }

    &-date, &-job-offer, &-source {
      font-size: 15px;
      margin-bottom: 10px;
    }

    &-send-email {
      font-size: 13px;
    }

    .ats-detail-other-candidacies-link {
      color: $light-red;
      font-weight: 500;
      text-decoration: underline;
    }

    &-send-email, &-send-sms {
      margin-right: 10px;
    }
  }

  .ats-detail-more-infos, .ats-detail-comments, .ats-detail-tags, .ats-detail-message, .ats-detail-answer {
    overflow: hidden;
    border-radius: 14px;
    padding: 0;
    padding-top: 1rem;

    &-title {
      border-radius: 14px 14px 0 0;
      display: flex;
      align-items: center;
      height: 56px;
      font-size: 20px;
      font-weight: 900;
      line-height: normal;
      padding: 0 1rem;
      background: $black;
      color: $white;
    }

    &-content {
      padding: 1rem 2rem;
    }
    &-item {
      margin-bottom: 10px;
      &-title {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }

  .ats-detail-more-infos, .ats-detail-message, .ats-detail-answer {
    &-content {
      border: 2px solid $black;
      border-radius: 0 0 14px 14px;
    }
  }

  .ats-detail-more-infos {
    margin-left: 0;
    height: 100%;
  }

  .ats-detail-message {
    &-content {
      padding: 3rem 2rem;
      &-scroll {
        padding: 0 0.5rem;
        max-height: 450px;
        overflow-y: auto;
        @extend .custom_scroll_bar;
      }
    }
  }

  .ats-detail-comments, .ats-detail-tags {
    padding-top: 0;
    overflow: visible;

    .myaladom-box-shadow {
      border-radius: 14px;
    }
  }

  .ats-detail-comments {
    margin-right: 0;
  }

  .ats-detail-tags {
    height: 100%;

    #ats-detail-tags-input {
      width: 24px;
      height: 24px;
      margin-left: 1rem;
      padding: 0;

      .dropdown-toggle {
        width: 24px;
        height: 24px;
        padding: 0;
        background-color: transparent;
        border-radius: 12px;
        border: none;

        &::after {
          display: none;
        }
      }
    }

    &-add {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      color: $white;
      background-color: $electric-blue;
      font-size: 0.75rem;
      border-radius: 12px;
      cursor: pointer;
    }
    &-delete {
      float: right;
      color: $white;
      text-decoration: none;
      font-size: 1.5rem;
      i {
        font-weight: bold;
        float: right;
      }
    }

    &-content {
      min-height: 70px;

      .ats-tag {
        margin-right: 0.75rem;
      }
    }
  }

  .ats-detail-cv {
    &-title {
      font-size: 25px;
      font-weight: 900;
      margin-bottom: 25px;
      margin-top: 20px;
    }
  }

  .ats-detail-other-candidacies, .ats-detail-history {
    margin-top: 3rem;

    &-title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 47px;
      padding-left: 1.5rem;
      margin-bottom: 1.5rem;
      color: $white;
      font-size: 1.25rem;
      font-weight: 800;
    }
  }

  .ats-detail-other-candidacies {

    &-title {
      background-color: $light-red;
    }

    &-content {
      table {
        width: 100%;
        color: $black;
        thead {
          th {
            padding-left: 2rem;
            font-size: 1.25rem;
            font-weight: bold;
          }
        }
        tbody {
          border: 2px solid $black;
          td {
            padding: 1rem 2rem 1rem;
            border-left: 2px $black solid;
            border-right: 2px $black solid;
          }
        }
      }
    }
  }

  .ats-detail-history {
    &-title {
      background-color: $black;
    }

    &-list {
      padding: 1.5rem;
      &-item {
        padding: 0 20px 25px;
        border-left: 4px solid $black;
        position: relative;
        overflow-wrap: break-word;
        margin: 10px 5px;
        font-size: 15px;

        &:before {
          content: "";
          position: absolute;
          left: -12px;
          top: 0;
          width: 20px;
          height: 20px;
          background-color: $black;
          border-radius: 50%;
        }

        i[class^=ala-] {
          font-size: 1.4rem;
          font-weight: 600;
        }

        &-date {
          font-weight: 800;
          padding-bottom: .5rem;
        }

        button.btn-collapse {
          padding: 0 .4rem;
          margin-left: .5rem;
          border-radius: 5px;
          line-height: 1.1rem;

          &.collapsed {
            transform: rotate(180deg);
          }

          i {
            font-size: .75rem;
            font-weight: 500;
          }
        }
        p {
          display: initial;
        }

        .bubble-rating {
          max-width: 70px;
        }
        p {
          display: initial;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .ats-detail-return-btn {
      font-size: .7rem;
      padding: 6px;
    }

    .ats-detail-main-infos {
      &-email, &-phone, &-city {
        font-size: 14px;
      }

      &-date, &-job-offer {
        font-size: 12px;
      }

      #status-input button, .ats-detail-main-infos-send-email, .ats-detail-main-infos-send-sms {
        padding: 6px;
        font-size: 14px;
      }

      .ats-detail-main-infos-send-email, .ats-detail-main-infos-send-sms {
        font-size: 12px;
      }
    }

    .ats-detail-more-infos {
      &-title {
        font-size: 16px;
      }

      &-item {
        font-size: 12px;
        &-title {
          font-size: 14px;
        }
      }
    }

    .ats-detail-cv-title {
      font-size: 20px;
    }

    .ats-detail-history {
      &-title {
        font-size: 16px;
      }
      &-list-item {
        font-size: 12px;
      }
    }
  }
}

.vue-daterange-picker {
  width: 100%;
  text-align: center;

  .daterangepicker td.active, .daterangepicker td.active:hover, .daterangepicker .ranges li.active {
    background-color: $electric-blue;
  }
  .calendar-header, .calendar-footer {
    background-color: $electric-blue;
    padding: 0.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: $white;
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }
}

// ATS tags
///////////

.tags-managed-link {
  margin-left: auto;
}
.tags-color {
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 15px;
  border: 1px solid $black;
  &.is-selected {
    border:5px solid $red;
  }
}
.tags-managed {
  i {
    font-size: 1.2rem;
    font-weight: bold;
  }
  i.ala-trash {
    color: $red;
  }
  i.ala-edit {
    color: $green;
  }
}
div.bloc-btn-add-tags {
  position: absolute;
  width: 100%;
  @include media-breakpoint-down(md) {
    position: unset;
  }
}
.btn-add-tag {
  border: 0;
  border-radius: 25px;
  color: $white;
  background: $electric-blue;
  padding: .5rem 1rem;
  font-size: 1rem;
  span {
    font-size: 1.2rem;
  }
}
div.box-tags {
  background: $off-white;
  max-width: 600px;
  button {
    text-decoration: none;
    background: transparent;
    border-radius: 50px;
    border: 0;
  }
}
li.tag-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.ats-tag {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 500px;
  font-weight: bold;
  color: $white;

  &.ats-tag-can-remove {
    padding-right: 0.5rem;
  }

  &-delete {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-left: 0.25rem;
    border-radius: 10px;
    cursor: pointer;
    i {
      font-size: 10px;
      transform: rotate(45deg);
    }
    &:hover {
      background-color: #ced4da41;
    }
  }
}

.ats_restricted {
  cursor: pointer !important;
  pointer-events: visible !important;
  position: relative !important;
  opacity: .65;

  &.ats_restricted_right, &.ats_restricted_up_right {
    &::after {
      opacity: 1 !important;
      z-index: 100;
      position: absolute;
      top: 50%;
      right: 0;
      margin: 0 !important;
      width: 1.5rem !important;
      height: 1.5rem !important;
      padding: .25rem;
      content: '' !important;
      background-image: url('/ui/static/icons/svg/yellow/lock.svg') !important;
      background-size: 1rem !important;
      background-repeat: no-repeat;
      background-position: center;
      border: none !important;
      border-radius: 5px;
    }
    &:hover::after{
      background-color: $black !important;
      background-image: url('/ui/static/icons/svg/yellow/unlock.svg') !important;
    }
  }
  &.ats_restricted_right::after {
    top: 50%;
    transform: translateY(-50%) !important;
  }
  &.ats_restricted_up_right::after {
    top: 0;
    transform: translate(50%,-50%) !important;
  }

  &.ats_restricted_left, &.ats_restricted_up_left {
    &::before {
      overflow: visible !important;
      z-index: 100;
      position: absolute;
      top: 50%;
      left: 0;
      margin: 0 !important;
      width: 1.5rem !important;
      height: 1.5rem !important;
      padding: .25rem;
      content: '' !important;
      background-image: url('/ui/static/icons/svg/yellow/lock.svg') !important;
      background-size: 1rem !important;
      background-repeat: no-repeat;
      background-position: center;
      border: none !important;
      border-radius: 5px;
    }
    &:hover::before{
      background-color: $black;
      background-image: url('/ui/static/icons/svg/yellow/unlock.svg') !important;
    }
  }

  &.ats_restricted_up_left::before {
    top: 0;
    transform: translate(-50%,-50%) !important;
  }

  &.ats_restricted_left::before {
    transform: translateY(-50%) !important;
  }
}

.ats-upgrade-modal {
  background: $blue-gradient-reverse !important;
  padding-left: 5rem !important;

  &-title {
    display: flex;
    margin-bottom: 2rem;
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border: $black solid 1px;
      border-radius: 10px;
      font-size: 3rem;
      margin-right: 2rem;
    }
    &-text {
      display: flex;
      align-items: center;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: bold;
    }
  }

  &-content {
    ul {
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 1rem;
      }
    }
  }

  &-action {
    margin: 3rem 0 2rem;
    .btn {
      padding: 1rem 2rem;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: unset;
    }
  }
}