@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "vars";
@import "mixins";
@import "options";
@import "specific";
@import "ats";

body.myaladom {
  header {
    margin-bottom: .5rem;
  }

  h1 {
    color: $black;
    font-weight: 900;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      font-size: 1.3rem;
    }
  }

  h2 {
    color: $black;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1.3rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
      margin-top: 4rem;
    }
  }

  .page-icon {
    height: 65px;
    font-size: 4rem;
    font-weight: 400;
    color: $black;
    padding-right: 1.5rem;
    max-width: 100px;
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }

  .sub-page-icon {
    height: 48px;
    font-size: 3rem;
    font-weight: 400;
    color: $black;
    padding-right: 1.5rem;
    max-width: 80px;
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  .page-description {
    margin-bottom: -.3rem;
    p {
      color: $black;
      font-size: .8rem;
      @include media-breakpoint-down(sm) {
        padding-bottom: .6rem;
      }
    }

    h2 {
      margin-top: 0;
    }
  }
  h1 {
    margin-top: .5rem;
  }
  .page-title-without-desc {
    @include media-breakpoint-up(sm) {
      margin-top: 1rem;
    }
  }

  .myaladom-picture-input figure {
    @include offer-provider-picture(180px);
    box-shadow : $default-shadow;
    border: none;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      width: 100px;
      height: 100px;
    }
  }

  @mixin myaladom-dashboard-picture($color) {
    .myaladom-dashboard-picture {
      circle {
        fill: white;
        stroke: $color;
        stroke-width: 70;
        stroke-dasharray: 0 158;
        transition: stroke-dasharray 3s ease;
      }

      svg {
        margin: 0 auto;
        transform: rotate(-90deg);
        background: white;
        border-radius: 50%;
        display: block;
      }

      border-radius: 50%;
      overflow: hidden;
      max-width: 100px;
      position: relative;
      margin: 0;

      img {
        border-radius: 50%;
        position: absolute;
        width: 76px;
        height: 76px;
        top: calc(50% - 38px);
        left: calc(50% - 38px);
      }
    }
  }

  // Particular dashboard
  @mixin myaladom-dashboard($color) {
    background-color: rgba($color, .12);
    color: $black;

    h1 {
      color: $color;
      text-transform: initial;
      font-weight: 700;
      font-size: 1.8rem;
      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
      }
    }

    h2 {
      color: black;
      text-transform: initial;
      font-size: .95rem;
      font-weight: 800;
      margin: 0;
      @include media-breakpoint-down(md) {
        font-size: 1.1rem;
        font-weight: 900;
      }
    }

    @include media-breakpoint-down(md) {
      line-height: 1.3;
    }

    .particular-dashboard-color {
      color: $color;
    }

    #myaladom-main-container {
      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-left: 0;
        overflow: hidden;
      }
    }

    .dashboard-profile-box {
      background-color: #f8f9fa;
      a {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
      }
      h2 {
        font-size: 1.2rem;
        margin-top: 0;
      }
      h3 {
        font-size: .7rem;
        color: black;
        text-transform: uppercase;
        margin-right: 10px;
      }
      .btn.btn-link {
        padding: 0;
        color: $color;
        &:hover {
          text-decoration: underline;
        }
      }

      .profile-completion-percent {
        color: $color;
        font-size: 1.5rem;
        font-weight: 900;
        display: block;
        @include media-breakpoint-down(md) {
          font-size: 2.2rem;
        }
      }
      .option-card-badge {
        margin-top: -8px;

        img {
          width: 27px;
        }
      }

      @include myaladom-dashboard-picture($color);

      .dashboard-profile-box-reviews a {
        color: black;
        font-size: .8rem;
        font-weight: 600;
      }
      .rating-stars {
        text-align: left;
        font-size: 1rem;
        @include media-breakpoint-down(md) {
          margin: unset;
          display: unset;
        }

        i.ala-star-empty {
          color: #707070;
        }
      }
      .dropdown-contacts-aladom {
        button {
          width: 100%;
        }
      }
    }

    .dashboard-main-features {
      .col {
        padding-left: 7px;
        padding-right: 7px;
      }

      i {
        color: $color;
        font-size: 3rem;
        @include media-breakpoint-up(md) {
          font-size: 2.1rem;
        }
      }

      .dashboard-feature {
        min-height: 150px;
        @include media-breakpoint-down(md) {
          min-height: 140px;
        }
        color: black;
        cursor: pointer;
        transition: box-shadow 500ms;
        box-shadow: $default-shadow, inset 0 0 0 #00000029;
        &:hover {
          color: black;
          text-decoration: none;
          box-shadow: 0 0 0 #00000029, $default-inside-shadow;
        }

        h2 {
          margin-bottom: 0;
          margin-top: 0;
        }

        p {
          font-size: .7rem;
          line-height: .7rem;
          margin-bottom: 0;
        }

        &-text {
          display: none;
          @include media-breakpoint-up(md) {
            display: flex;
          }
        }
      }
    }

    .dashboard-feature-secondary {
      color: black;
      border: 3px solid transparent;
      transition: border 200ms;
      position: relative;
      padding: 1.5rem;

      &:hover {
        border: 3px solid $color;
        color: black;
        text-decoration: none;
      }

      &.feature-rounded {
        @include media-breakpoint-up(md) {
          border: 3px solid $color;
          box-shadow:inset 0 0 0 4px transparent;
          transition: box-shadow 500ms;
          &:hover {
            -webkit-box-shadow:inset 0 0 0 4px $color;
            -moz-box-shadow:inset 0 0 0 4px $color;
            box-shadow:inset 0 0 0 4px $color;
          }
        }
      }

      &.feature-jumbotron {
        background-color: $color;
        color: white;
        text-align: center;
        h2 {
          color: white;
          font-size: 1.7rem;
        }
        p {
          font-weight: 600;
        }
        @include media-breakpoint-down(md) {
          padding-right: 0;
          padding-left: 0;
          .btn.btn-black {
            background-color: black;
          }
        }
      }

      .btn {
        text-transform: unset;
      }

      div + h2 {
        margin-top: unset;
      }

      h3 {
        color: black;
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 700;
      }

      .feature-icon {
        position: absolute;
        width: 50px;
        height: 50px;
        top: -25px;
        right: calc(50% - 25px);
        @include media-breakpoint-up(md) {
          width: 32px;
          height: 32px;
          top: -16px;
          right: calc(50% - 16px);
        }
        text-align: center;
        i {
          font-size: 1.8rem;
          font-weight: bolder;
          background-color: white;
          border-radius: 50%;
        }
        &.feature-icon-check {
          color: $green;
        }
        &.feature-icon-deny {
          color: #ff6767;
        }
        &.feature-icon-part-emp {
          color: white;
          background-color: $part-emp-color;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 1.7rem;
            @include media-breakpoint-up(md) {
              font-size: 1.2rem;
            }
            background-color: transparent;
          }
        }
        &.feature-icon-visi {
          color: white;
          background-color: $color;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 1.7rem;
            @include media-breakpoint-up(md) {
              font-size: 1.2rem;
            }
            background-color: transparent;
          }
        }
      }

      .subcategories_list {
        button {
          width: 37px;
          padding: .5rem;
        }
        i {
          font-size: 1.1rem;
        }
      }

      #prime_option_box {
        padding: 1rem;
        box-shadow: none;
        font-size: .7rem;
        background: unset;
        i {
          position: absolute;
          left: 0;
          top: calc(50% - 10px);
          font-size: 1.3rem;
          color: $cyan;
          @include media-breakpoint-down(sm) {
            left: -25px;
            font-size: 1.5rem;
          }
        }
        li {
          padding: .4rem 0 .4rem 4rem;
        }
      }

      .btn-mobile-full {
        @include media-breakpoint-down(md) {
          padding: 0 !important;
          .btn {
            border-radius: 0;
          }
        }
      }
    }

    .notifs-slider {
      overflow-x: auto;
      white-space: nowrap;
      .dashboard-notif {
        background-color: $white;
        padding: 2rem;
        position: relative;
        height: 180px;
        display: inline-flex;
        p {
          font-size: .875rem;
        }
        .notif-state-label {
          padding: .5rem 1rem;
          width: fit-content;
          position: absolute;
          top: 0;
          transform: translateY(-50%);
          &-new {
            background-color: $yellow-gold;
          }
          &-info {
            background-color: $checked-identity-color;
          }
          &-reminder {
            background-color: #FD6767;
          }
          &-advice {
            background-color: $prime-blue;
          }
        }
        &-list {
          a {
            font-weight: 800;
            background-color: #2653FB20;
            border-radius: 10px;
            padding: .5rem 1rem;
            &:hover {
              cursor: pointer;
            }
          }
        }
        &-btn-close {
          font-weight: 500;
          border-radius: 50%;
          height: 25px;
          width: 25px;
          background-color: rgba(250,250,250,.8);
          display: flex;
          justify-content: center;
          align-items: center;
          right: 20px;
          top: 15px;
          position: absolute;
          &:hover {
            background-color: rgba(250,250,250,.5);
          }
          span {
            color: black;
            display: flex;
            align-items: center;
          }
        }
        &-description {
          word-wrap: break-word;
          white-space: normal;
          max-width:500px;
        }
      }
    }

    .myaladom-options-info {
      .col {
        padding-left: 7px;
        padding-right: 7px;
      }
      .visibility-options-info-block {
        @include media-breakpoint-down(md) {
          flex-direction: row;
        }
        font-size: .7rem;
        line-height: 1.2;
        text-align: left;
        align-items: start;
        .aladom-option-bubble-info {
          display: none !important;
        }
        i {
          margin-right: 1rem;
        }
      }
      i {
        font-size: 1.5rem;
        margin-bottom: .7rem;
      }
    }
    .training-block {
      background-color: $pro-blue;
      color: $white;
      padding: 2rem;
      position: relative;
      display: inline-flex;
      border-radius: 20px;
      width: 100%;
      .row {
        width: 100%;
      }
      .btn-gold:hover {
        color: $yellow-gold;
        border: 1px solid $yellow-gold
      }
    }
    img#spaceship {
      width: 170px;
      position: absolute;
      right: 15px;
      bottom: 0;
      border-radius: 0 0 19px 0;
    }
  }
  // end of @mixin myaladom-dashboard($color)

  &.myaladom-dashboard {
    &.myaladom-part-emp {
      @include myaladom-dashboard($part-emp-color);
    }
    &.myaladom-presta {
      @include myaladom-dashboard($presta-color);
    }
    &.myaladom-freelance, &.myaladom-pro {
      @include myaladom-dashboard($pro-blue);
    }
  }

  &.my-job-search-group {
    &.myaladom-presta {
      background-color: rgba($presta-color, .12);
    }
  }
  // Pro dashboard
  &.myaladom-pro-dashboard {
    line-height: normal;
    @include myaladom-dashboard-picture($pro-blue);
    .box-product {
      .grid-box-dashboard {
        display: grid;
        grid-template-rows: auto;
        grid-gap:3% 2%;
        margin: 1.25rem;
        @include media-breakpoint-down(lg) {
          display: unset;
          margin-bottom: 1.25rem;
          .box-white {
            margin: 1rem;
          }
        }
        &-recruitment {
          grid-template-columns: 3fr 5fr 2fr;
          .resume-candidacy-box {
            grid-area: 1 / 2 / 2 / 3;
          }
        }
        &-brand {
          grid-template-columns: 3fr 2fr 2fr 2fr;
        }
        &-leads {
          grid-template-columns: 3fr 3fr 4fr 2fr;
          p {
            .text-no-leads {
              font-size: .75rem;
            }
          }
        }
        .resume-offer-box {
          grid-area: 1 / 1 / 2 / 2;
        }
        .shortcut-menu-buttons {
          display: grid;
          grid-template-columns: 1fr;
          gap: 5% 0;
          @include media-breakpoint-down(lg) {
            display: none;
          }
          i {
            font-size: 1.25rem;
            font-weight: 700;
          }
          .dashboard-feature {
            height: 100%;
            cursor: pointer;
            transition: box-shadow 500ms;
            box-shadow: $default-shadow, inset 0 0 0 #00000029;
            &:hover {
              color: black;
              text-decoration: none;
              box-shadow: 0 0 0 #00000029, $default-inside-shadow;
            }
          }
        }
      }
      .box-gray {
        background-color: #f8f9fa;
        .box-white:first-child {
          position: relative;
        }
        .box-white {
          background-color: #EFF0F2;
        }
        .box-advice, .box-parameters-leads {
          .col-2 {
            padding-right: 10px;
          }
        }
        .box-advice {
          background-color: #060C27;
          height: fit-content;
          margin: 1.25rem;
          p {
            color: white;
          }
          #selector-advice {
            i {
              font-size: 2rem;
            }
            .icon-plus-advice {
              i {
                font-size: 1rem;
              }
            }
          }
        }
        .box-parameters-leads {
          &-help {
            display: flex;
            align-items: center;
            background-color: $gray-400;
            border-radius: 4px;
            width: fit-content;
            font-weight: normal;
            font-size: 0.75rem;

            i {
              font-size: 1.25rem;
              margin-right: 1rem;
            }
          }
        }
        .icon-plus {
          font-size: 2rem;
          font-weight: 500;
          padding: 1rem;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          @include media-breakpoint-down(md) {
            width: 20px;
            height: 20px;
            margin: auto;
          }
          &-advice {
            background-color: rgba(255,255,255,.25);
            padding: unset;
            i {
              color:white;
            }
          }
          &-leads {
            background-color: rgba(0,0,0,.25);
            span {
              color: black;
            }
            i {
              font-size: 1rem;
            }
          }
          .position-icon-plus {
            position: absolute;
            transform: translate(-50%,-50%);
            left: 50%;
            top: 50%;
          }
        }
      }
      .product-tag {
        background-color: #060C27;
        border-radius: 20px 0;
        padding: .5rem 3rem;
        width: fit-content;
        color: white;
        &-alert {
          position: absolute;
          top:0;
          right: -15px;
          transform: translateY(-50%);
          border-radius: 30px;
          padding: 1rem;
          z-index: 2;
          width: 50px;
          height: 50px;
          &-yellow {
            background-color: $yellow-gold;
          }
          &-red {
            background-color: $red;
          }
          i {
            font-size: 1rem;
            color: black;
            &:hover {
              opacity: .5;
            }
          }
        }
      }
      .product-tag-alert-info {
        &-yellow {
          background-color: #FDE68E;
          @include media-breakpoint-up(md) {
            margin-left: -1rem;
          }
        }
        &-red {
          background-color: #F5C2C7;
        }
      }
      .product-tag-alert-info {
        top: 0;
        min-height: 100%;
        line-height: normal;
        position: absolute;
        padding-right: .5rem;
        z-index: 1;
        @include media-breakpoint-down(lg) {
          position: unset;
          margin: 1rem;
          padding: 2rem;
        }
        @include media-breakpoint-down(sm) {
          position: unset;
          margin: unset;
          padding: unset;
        }
      }
      .product-tag-alert-info:not(.active) {
        display: none;
      }
      .bloc-pusher-ats {
        &-header {
          background-image: $blue-gradient;
          border-radius: 20px 0 0 20px;
          .h2, .h3 {
            color: white;
          }
        }
      }
      .grid-news {
        display: inline-grid;
        grid-template-columns: 4fr 4fr 4fr;
        grid-gap: 3%;
        @include media-breakpoint-down(md) {
          grid-template-columns: 100%;
          margin-bottom: 3rem;
        }
      }
    }
  }
}

#myaladom-nav-mobile-container {
  display: none;
  width: $menu-width-collapsed;
  cursor: pointer;
  z-index: 1002;
  @include media-breakpoint-down(md) {
    position: fixed;
    top: 10px;
    display: flex;
  }

  .myaladom-nav-mobile-togglebar {
    width: $menu-width-collapsed;
    height: 100px;
    background-color: $gray-100;
    border-radius: 0 $menu-radius-collapsed $menu-radius-collapsed 0;
    box-shadow: $default-shadow;

    &-profil-img {
      align-items: center;
      border-radius: 50%;
      box-shadow: $default-shadow;
      width: 32px;
      height: 32px;
      margin: 10px 5px;

      img {
        border-radius: 50%;
      }
    }

    &-icon {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 5px;
      left: 9px;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;
      input {
        display: flex;
        width: 40px;
        height: 32px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
      }
      span {
        display: flex;
        width: 24px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: $black;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 5px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease;
      }
    }
  }
}

#myaladom-main-container {

  margin-left: 45px;
  margin-right: 45px;
  transition: margin $transition-time ease-in-out;

  &:not(.nav-collapsed) {
    @include media-breakpoint-up(lg) {
      margin-left: 340px;
      margin-right: 15px;
      transition: margin $transition-time ease-in-out;
    }

    footer {
      width: calc(100% + 80px);
    }
  }

  footer {
    width: calc(100% + 110px);
    transform: translateX(-65px);
    padding-left: 65px;
    overflow: visible;
  }

  @include media-breakpoint-down(md) {
    margin-left: 15px;
    margin-right: 15px;

    footer {
      width: calc(100% + 80px);
    }
  }

  &.myaladom_min_mobile_margin {
    @include media-breakpoint-down(sm) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

#myaladom-nav-container{
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0 $menu-radius-collapsed $menu-radius-collapsed 0;
  background-color: $gray-100;
  height: 100%;
  z-index: 1002; //1002 because chat box has 1000
  width: $menu-width-not-collapsed;
  transition: $transition-time ease-in-out;
  float: left;
  box-shadow: $default-shadow;

  #myaladom-nav-wrapper, #myaladom-nav{
    height: 100%;
    width: 100%;
  }

  .nav:not(.collapse){
    display: block;
  }

  @include media-breakpoint-down(md) {
    position: absolute;
    background-color: $gray-100;
    top:0;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
  }

  .myaladom-nav-header {
    transition: $transition-time ease-in-out;
    padding-top: 10px;
    border-radius: 0 $menu-radius-collapsed 0 0;
    background-color: $gray-100;
    z-index: 1;
    justify-content: center;
  }

  .myaladom-nav-bottom {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: fixed;
    bottom: 0;
    @include media-breakpoint-down(xs){
      width: 100% !important;
    }
    .nav-alone.nav-helps {
      height: 45px;

      .nav-link {
        font-size: 1rem;
        font-weight: 600;
        width: 100%;
        padding: .75rem;
        border-radius: unset;
      }
      i {
        font-size: 1.25rem;
        font-weight: 900;
        color: $black;
      }
    }
  }


  .myaladom-nav-toggle-btn, .myaladom-nav-logout {
    transition: $transition-time ease-in-out;
    display: flex;
    height: $menu-width-collapsed;
    width: 100%;
    padding: 0;
    align-items: center;
    border-radius: 0 0 $menu-radius-collapsed 0;
    i {
      transition: $transition-time ease-in-out;
      font-size: 24px;
    }
  }

  .myaladom-nav-toggle-btn {
    color: $black;
    background-color: $gray-100;
    padding-left: 12px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    i {
      border-right: 1px solid $black;
    }
  }

  .myaladom-nav-logout {
    color: $white;
    background-color: $black !important;
    justify-content: center;
    h3 {
      background-color: unset !important;
    }

    &:hover {
      text-decoration: none;
    }

    span, i {
      font-weight: 600;
      font-size: 15px;
    }
  }

  &.pro {
    a, h3 {
      background-color: #E0E2E6;
    }
  }

  &.part {
    &.nav-container-part-emp {
      .nav-link-presta {
        display: none !important;
      }
      a, h3 {
        background-color: #E8F5F6;
      }
    }
    &.nav-container-presta {
      .nav-link-part-emp {
        display: none !important;
      }
      a, h3 {
        background-color: #E4EAFE;
      }
    }
  }

  a.myaladom-nav-home-link {
    &:hover {
      text-decoration: none;
    }

    h3 {
      span {
        padding: .5rem 1rem;
        border: solid $black;
        border-radius: 30px;
      }
      background-color: unset !important;
      justify-content: center;
      font-weight: 800;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1rem;
    letter-spacing: 0px;
    padding: .75rem;
    margin: .75rem 1rem;
    border-radius: 8px;
    color: $black;
    background-color: #BDCCFE;

    i {
      font-size: 1.25rem;
      font-weight: 800;
      margin-right: 15px;
    }
  }

  .myaladom-nav-mobile-togglebar-icon-close {
    display: none;
  }

  .myaladom-nav-item-title{
    color: $black;
  }

  #myaladom-user-card {
    @include media-breakpoint-down(md) {
      margin-top: 2.5rem;
    }
    a {
      background-color: unset;
    }

    figure {
      width: 150px;
      height: 150px;
      box-shadow: $default-shadow;
      border: none;
    }

    a:hover {
      text-decoration: none;
    }

    .option-card-badge img {
        width: 24px;
    }

    .myaladom-user-card-status {

      p{
        margin-bottom: .5rem;
      }

      p:first-of-type {
        font: normal normal bold 16px/22px Montserrat;
      }
      > div:first-of-type {
        font: normal normal 500 18px/22px Montserrat;
        display: flex;
      }

      &-freelance {
        color: $black;
      }

      &-part-emp {
        color: $cyan;
      }

      &-presta {
        color: $electric-blue;
      }

      #myaladom-user-card-status-info-logo {
        @include info-logo($black);
        justify-content: center;
        display: flex;

        .dropdown-menu {
          display: none;
        }
      }
    }
  }

  &.collapsing {
    #myaladom-user-card {
      opacity: 0;
    }
  }
  &:not(.collapsing) {
    #myaladom-user-card {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &.collapsed {

    transition: $transition-time ease-in-out;
    min-width: $menu-width-collapsed;
    width: $menu-width-collapsed;

    @include media-breakpoint-down(md) {
      transform: translate(-100%, 0);
      transition: transform $transition-time ease-in-out;
    }

    .myaladom-nav-header {
      transition: $transition-time ease-in-out;
      height: $menu-width-collapsed;
    }
    .myaladom-nav-toggle-btn, .myaladom-nav-header {
      transition: $transition-time ease-in-out;
      width: $menu-width-collapsed;
    }

    h3 {
      margin: 2px 0;
      border-radius: 0;
    }

    #myaladom-nav-body {
      margin-top: 75px;

      .myaladom-nav-block {
        h3:hover {
          color: $white;
          background-color: #00012b60;
          border-radius: 0 10px 10px 0;
        }

        .nav-pills.collapsing {
          display: none;
        }
      }
    }

    .myaladom-nav-block:hover .dropdown-menu {
      display: block;
      padding-top: 0;
      margin-top: 0;
      border-radius: 5px;
      font: normal normal normal 13px/15px Montserrat;

      .dropdown-menu-title {
        padding: .5rem 1rem;
        color: $black;
        background: #00012b60;
        font: normal normal 800 16px/19px Montserrat;
        text-transform: uppercase;
        border-radius: 5px;
        text-align: center;
      }
      .myaladom-nav-item-title {
        display: inline;
        padding: 5px;
      }

      .nav-link {
        padding: 5px 1rem;
      }

      i, img {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      transform: translate(-120%, 0);
      transition: $transition-time ease-in-out;
    }

    #myaladom-user-card {
      min-height: 0;
      height: $menu-width-collapsed;
      width: $menu-width-collapsed;
      padding: 0;

      .myaladom-nav-profil-img{
        transition: $transition-time ease-in-out;
        width: 35px;
        height: 35px;
        margin: 5px;
      }

      .option-card-badge {
        display: none;
      }

      .myaladom-user-card-status {
        display: none;
      }

      .myaladom-nav-item-title {
        transition: width $transition-time ease-in-out;
        width: 0;
      }
    }

    .a {
      transition: $transition-time ease-in-out;
      width: $menu-width-collapsed;
    }

    .myaladom-nav-home-link {
      transition: $transition-time ease-in-out;
      display: none;
    }

    .btn:not(.myaladom-nav-toggle-btn) {
      transition: $transition-time ease-in-out;
      width: 35px;
      height: 35px;
    }

    .myaladom-nav-item-title, .new-feature {
      display: none;
    }

    .myaladom-nav-bottom {
      width: $menu-width-collapsed;
    }
  }

  &:not(.collapsed) {
    transition: $transition-time ease-in-out;
    border-radius: 0 28px 28px 0;

    #myaladom-nav-wrapper {
      padding: 30px 0;

      #myaladom-nav {
        overflow-y: auto;

        &.has-help-button {
          #myaladom-nav-body {
            margin-bottom: 75px;
          }
        }
      }
    }

    .myaladom-nav-header {
      transition: $transition-time ease-in-out;
      border-radius: 0 $menu-radius-not-collapsed 0 0;
      width: $menu-width-not-collapsed;
    }

    .myaladom-nav-toggle-btn, .myaladom-nav-logout, .myaladom-nav-header, .myaladom-nav-bottom {
      transition: $transition-time ease-in-out;
      width: $menu-width-not-collapsed;
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    .myaladom-nav-toggle-btn, .myaladom-nav-logout {
      border-radius: 0 0 $menu-radius-not-collapsed 0;
      @include media-breakpoint-down(xs) {
        border-radius: 0;
      }
    }

    .myaladom-nav-header {
      border-radius: 0 $menu-radius-not-collapsed 0 0;
    }

    .myaladom-nav-toggle-btn {
      i {
        transition: $transition-time ease-in-out;
        transform: rotate(180deg);
      }
    }

    #myaladom-user-card {
      width: 100%;
      .col-5, .col-7 {
          margin:auto;
        }

      .myaladom-nav-profil-img{
        transition: $transition-time ease-in-out;
        width: 100px;
        height: 100px;
      }

      .myaladom-user-card-status-info {
        &:hover, &.show {
          .dropdown-menu {
            display: block;
          }
        }
      }

      .myaladom-user-card-status-info {

        .dropdown-menu {
          @include media-breakpoint-down(sm) {
            top: 0;
            right: 100%;
            left: auto;
            margin-right: .125rem;
          }
          top: 0;
          right: auto;
          left: 100%;
          margin-left: -15rem;
          margin-top: .5rem;
          display: none;
          background-color: $gray-200;
          border-radius: 10px;
          box-shadow: $default-shadow;
          padding: 20px 25px;
          @include media-breakpoint-up(md) {
            min-width: 250px;
          }
        }
      }

      .myaladom-nav-item-title {
        transition: width $transition-time ease-in-out;
      }

      .option-card-badge {
        display: flex;
        margin: auto;
        justify-content: center;
      }
    }

    #myaladom-nav-body {
      transition: $transition-time ease-in-out;
      margin-bottom: 5px;
      width: $menu-width-not-collapsed;
      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      .myaladom-nav-block {

        cursor: pointer;

        .nav-link {
          width: 273px;
          margin-left: 32px;
          margin-right: 15px;
          font-size: 14px;

          i {
            font-size: 17px;
            margin-right: 12px;
          }
          &.active, &:hover {
            border-right: 5px solid $black;
          }
        }

        .dropdown-menu-title {
          display: none;
        }
      }
    }

    .nav-alone {
      i {
        margin-right: 15px;
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;
	  position: fixed;
      transition: $transition-time ease-in-out;
      overflow-y: auto;
	  overflow-x: hidden;
      height: 100vh;
	  -webkit-overflow-scrolling: touch;

      .myaladom-nav-mobile-togglebar-icon-close{
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 15px;
        cursor: pointer;
        color: $black;
        font-size: 35px;
        text-align: center;
      }

      .myaladom-nav-logout {
        h3, .myaladom-nav-item-title, i {
          color: $white;
        }

        h3 {
          justify-content: center;
          padding: 24px;

          i {
            color: $white;
            margin-right: 20px;
          }
        }
      }

      #myaladom-user-card {
        a {
          display: inline-block;
          margin: 0;
        }

        figure {
          width: 110px;
          height: 110px;
        }
      }

    }

    @include media-breakpoint-up(lg) {
      #myaladom-user-card {
        padding-right: 20px;
        .col-4 {
          margin:auto;
        }
      }
    }

    .btn:not(.myaladom-nav-toggle-btn) {
      transition: $transition-time ease-in-out;
      width: auto;
      height: auto;
    }
  }

  .btn:not(.myaladom-nav-toggle-btn) {
      margin: 5px;
  }

  .nav-icon-img {
    width: 25px;
    height: 25px;
    margin: 0 10px;
  }

  @include media-breakpoint-up(lg) {
      .myaladom-nav-logout {
        display: none;
      }
  }

  .myaladom-nav-block {
    color: $black;
    margin-bottom: 1px;

    @include media-breakpoint-up(lg) {
      border-right: 0;

      &:last-of-type{
        margin-bottom: 0;
      }
    }

    &:nth-of-type(2){
      border-bottom: none;
      margin-bottom: 0;
    }

    .nav-item {
      .unread-message, .to-validate-offers {
        margin-right: 1.5rem;
      }
    }

    & > .nav-pills .nav-link {
      display: flex;
      align-content: center;
      transition: 200ms ease-in-out;
      background-color: inherit;
      border-radius: 0;
      padding-left: 10px;
      color: $black;

      &.active, &:hover {
        font-weight: bold;
      }

      img {
        width:20px;
        max-height:32px;
        margin-right: 12px;
      }
    }
  }

  &.nowrap {
    white-space: nowrap;
  }

  .nav-link span.new-feature{
    min-width: 75px; // for be sure point and text was in same line
    margin-left: 10px;
    color: $electric-blue;
    font-size: 12px;
    font-weight: 900;

    &::before {
      background-color: $electric-blue;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      content: "";
      display: inline-block;
      margin-right: 5px;
    }
  }

  @include media-breakpoint-up(md) {
    #myaladom-nav-toggleable.collapse {
      display: block;
    }
    #myaladom-nav-toggler {
      display: none;
    }
  }
}

#myaladom-nav-container .btn-mini-site, #myaladom-container .btn-mini-site {
  border-radius: 500px;
  background-color: $white;
  color: $black;
  font-size: 12px;
  box-shadow: $default-shadow;
}

#myaladom-nav {
  .nav {
    &-item {
      position: relative;

      img:not(.nav-icon-img) {
        position: absolute;
        bottom: calc(50% - 12px);
        right: 5px;
      }
    }
  }
}

#myaladom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  max-width: 1300px;
  margin: auto;
  position: relative;
  .card-body {
    border:none;
  }
}

.myaladom .box {

  i.title-icon {
    font-size: 65px;
  }

  &.archived {
    background: $gray-300;
  }
}

.myaladom {
  #myaladom-message {
    .talkbubble-left, .talkbubble-right {
      min-height: 80px;
      background: $white;
      position: relative;
      padding: 20px;
      margin-left: 60px;
      margin-right: 60px;
    }
    .talkbubble-left:before, .talkbubble-right:before {
      content: "";
      position: absolute;
      top: 15px;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
    }
    .talkbubble-right:before {
      left: 100%;
      border-left: 13px solid $white;
    }
    .talkbubble-left:before {
      right: 100%;
      border-right: 13px solid $white;
    }
    .img-message img {
      border-radius: 50%;
      width: 40px;
      margin-top: .5rem;
    }
    .date-message-left, .date-message-right {
      font-size: 12px;
    }
    .date-message-left {
      margin-left: 60px
    }
    .date-message-right {
      margin-right: 60px
    }
    .hide-name-left, .hide-name-right {
      color: $blue;
      margin-top: -20px;
    }
    .hide-name-left {
      float: left;
      margin-left: 1rem;
    }
    .hide-name-right {
      float: right;
      margin-right: 1rem;
    }
    .box-message {
      background: $white !important;
    }

    @include media-breakpoint-up(lg) {
      .hide-name-left, .hide-name-right {
        display: none;
      }
      .box-message {
        margin: 0 60px;
      }
    }

    @include media-breakpoint-down(md) {
      .talkbubble-left, .talkbubble-right {
        margin-top: 40px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .date-message-left {
        margin-left: 10px;
      }
      .date-message-right {
        margin-right: 10px;
      }
      .img-message {
        display: none;
      }
      .box-message {
        margin: 0 10px;
      }
    }
  }

  .leads-list {
    .box-white {
      min-height: 100px;
      padding: .5rem;
      margin-bottom: .5rem;
    }
  }

  #page-header #page-header-full-user-box {
    display: flex;
    float: right;
    width: fit-content;
    background-color: $testimony-blue;
    border-radius: 0 0 20px 20px;
    &.show {
      border-radius: 0;
    }
    .myaladom-header-dropdown {
      a {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .dropdown-menu {
      top: 15px !important;
      width: 100%;
      transform: translate3d(0px, 50px, 0px) !important;
      border-radius: 0 0 20px 20px;
      .dropdown-item:active {
        color: white;
      }
      a {
        color: black;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          color: #00000060;
        }
      }
    }
  }
}

// Edit CV
//////////

.btn-cv {
  i {
    font-size: 1.2rem;
  }

  &.btn-danger:hover {
    background: $white;
    color: $light-red;
    border-color: $light-red;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &.smaller-font{
    font-size: .9rem;
  }
  .small-width-cell {
    max-width: 180px;
    overflow-wrap: break-word;
  }
}
.table-responsive.table-bordered {
  border: 0;
}
#mybrand-select-agency, #mybrand-select-brand {
  margin-bottom: 1.5rem;
  color: $white !important;
  background-color: $electric-blue;

  option {
    background-color: $white;
    color: $black;
  }
}

label[for=mybrand-select-agency], label[for=mybrand-select-brand] {
  font-size: 1.125rem;
  color: $electric-blue;
  text-transform: uppercase;
}

hr.mybrand-hr {
  border-top: 2px solid $gray-100;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
}
.text-normal {
  white-space: normal;
}

#myaladom-options {

  @include media-breakpoint-down(sm) {
    .h2 {
      font-size: 16px;
    }
  }

  div.myaladom-options-info {
    margin-bottom: 2rem;
    background-color: $gray-100;
    padding: 2rem 1rem;
    border-radius: 7px;

    &.active {
      border: 3px solid $black;
    }

    p {
      line-height: 30px;
    }
    @include media-breakpoint-up(lg) {
      div.box-white {
        min-height: 170px;
        font-size: .75rem;
        padding: .25rem;
        padding-top: 1rem;
      }
    }
    @include media-breakpoint-down(lg) {
      .col-lg-4 {
        flex: initial;
        max-width: initial;
        div.box-white {
          font-size: 1rem;
          height: 140px;
          padding: 1rem;
          p {
            margin-top: .5rem;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      img {
        width: 80%;
      }
    }
    @include media-breakpoint-down(xs) {
      div.box-white p {
        font-size: 1rem;
      }
    }
  }
}

.offer-option {
  background: $white;
  padding: 1rem;
  border: 3px solid $gray-400;
  cursor: pointer;
  margin-bottom: 1rem;
  .offer-option-info {
    img {
      width: 10%;
      max-width: 40px;
      float: left;
      margin-top: -.3rem;
    }
    margin-left: -.5rem;
    display: flex;
    flex-direction: column;
    div {
      margin-top: 2rem;
    }
  }
  &.primary-border {
    border: 3px solid map-get($theme-colors, primary) !important;
  }
}

iframe#cv_pdf {
  @include media-breakpoint-up(lg) {
    height: 400px !important;
  }
}

#collapse_CV_results {
  padding: 1rem;

  li {
    position: relative;
    padding: 1rem 1rem 1rem 2.5rem;

    i {
      margin-right: 0.5rem;
    }
  }
}

// Job offer listing
////////////////////
#job-offer-myaladom-listing {
  .offer-item-premium {
    button.btn, a.btn {
      padding: 0.25rem 0.25rem;
      line-height: 1;
    }
    div.card-header:hover {
      cursor: pointer;
    }
  }
  .offer-item, .offer-item-premium {
    .card-header,.card-premium {
      h6 {
        font-size: .875rem;
        font-weight: 400;
      }
    }
  }
  #example-offer {
    border-radius: 20px;
    .btn {
      position: relative;
    }
    .card-header {
      position: relative;
    }
    .example-offer-title {
      position: absolute;
      font-size: 4rem;
      font-weight: 600;
      opacity: 0.2;
      bottom : -45px;
      right: 0;
      left: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


// Pole emploi
//////////////

#myaladom-pole-emploi {
 .filter-box {
   .custom-controls-stacked {
     display: flex;
     @include media-breakpoint-down(sm) {
       display: unset;
     }
   }
 }
}

.table td.myaladom-pole-emploi-description {
  width: 60%;
  @include media-breakpoint-down(lg) {
    display: inline-block;
    width: 100%;
  }
}
.table td.myaladom-pole-emploi-action {
  vertical-align: middle;
  text-align: right;
  @include media-breakpoint-down(lg) {
    display: inline-block;
  }
  @include media-breakpoint-down(md) {
    text-align: left;
    .btn-outline-danger {
      margin-top: 1rem;
    }
  }
}

.aggregated {
  color: $green;
}

.not-aggregated {
  color: $red;
}


.box.box-white {
  background: $white;
}

.pole-emploi-filter-area {
  legend {
    font-size: 1rem;
  }
}

// Stripe payments
//////////////////

.myaladom-stripe-payment {

  legend {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #8898aa;
    padding: 3px 10px 7px;
    margin-bottom: 0;
  }

  .payment.confirm-payment-stripe {
    box-sizing: border-box;
    border: 1px solid transparent;
    box-shadow: 0 4px 6px 0 #cfd7df;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    font-size: 20px;
    background: $white;
    padding: 2rem;
    border-radius: 4px;

  }
}


// Pro profile
//////////////

#pro-member-profile-form #field-opening_hours, #member-complete-profile-step2-form #field-opening_hours {
  .form-control {
    font-size: small;
  }

  font-weight: bold;

  .opening_day:nth-child(even) {
    background: $ice-blue;
  }
  .opening_day:nth-child(odd) {
    background: #F5FAFF;
  }
}

// Domisy
/////////
.btn-delete-employee {
  float: right;
  border: none;
  background-color: $white;
  height: 50px;
  margin-top: -10px;
}


#myaladom-nav-toggleable {
  position: relative;
}

.cesu-img-menu {
  width: 60px;
  @include media-breakpoint-down(lg) {
    top: -.5rem;
    right : -.2rem;
  }
}

.domisy {
  .topright {
    position: absolute;
    top: -30px;
    right: -15px;
    width: 103px;
    height: 60px;
    background-image: url("/ui/static/img/cesu/Logo-Connect-CESU-02.svg");
  }
  @include media-breakpoint-down(md) {
    .topright {
      position: absolute;
      top: 0px;
      right: 25px;
    }
  }
  .employee-link {
    font-size: large;
  }
  ul.list-group.list-group-flush {
    margin-top: 10px;
  }
}

// CESU
.green-cesu {
  color: #33cc99;
}

// Chatbox
//////////

.member-inbox-container {

  color: #0a0a0a;
  margin: -2.5rem 0 -3rem !important;
  max-width: 1400px !important;

  .full-messages-box {
    height: 800px;

    .btn-delete-mobile {
      display: none;
    }

    .pagination {
      box-shadow: 0 -3px 6px rgba($black, 0.15);
      border: none;

      .page-item {
        &.active {
          display: block !important;
        }

        .page-link {
          padding: 0.3rem 0;

          i {
            font-size: 25px;
          }
        }
      }
    }
  }

  @mixin myaladom-inbox-bg($color) {
    background-color: $color;
    border-color: $color;
    @include media-breakpoint-down(sm) {
      background-color: $white;
    }
  }

  .message-api.active, #chat-box-container {
    &.myaladom-part-emp {
      @include myaladom-inbox-bg($inbox-part-emp);
    }

    &.myaladom-presta {
      @include myaladom-inbox-bg($inbox-presta);
    }

    // pro and freelance
    &.myaladom-pro {
      @include myaladom-inbox-bg($inbox-pro);
    }

    &.sm-hide {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .squared-picture {
    border-radius: 0.9rem;
  }

  .selector-chat-list {
    display: flex;
    flex-direction: row;
    height: 50px;
    box-shadow: 0 3px 6px rgba($black, 0.15);
    @include media-breakpoint-down(sm) {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
    }

    a {
      border-top: 2px solid;
    }

    &-part-emp {
      .active {
        a {
          background-color: $white;
          color: $part-emp-color !important;
        }
      }

      a, .bg-list {
        color: $white !important;
        background-color: $part-emp-color;
        border-color: $part-emp-color !important;
      }
    }

    &-presta {
      .active {
        a {
          background-color: $white;
          color: $presta-color !important;
        }
      }

      a, .bg-list {
        color: $white !important;
        background-color: $presta-color;
        border-color: $presta-color !important;
      }
    }

    &-pro {
      .active {
        a {
          background-color: $white;
          color: $black !important;
        }
      }

      a, .bg-list {
        color: $white !important;
        background-color: $black;
        border-color: $black !important;
      }
    }

    .bg-list {
      width: 50%;

      &:not(.active) {
        background-color: $white;
      }

      &:first-of-type {
        border-radius: 25px 0 0 0;
      }
    }

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 10px 30px;

      &:hover {
        text-decoration: none;
      }

      .disabled {
        pointer-events: none;
        opacity: .65;
      }
    }

    #list-message {
      border-left: 2px solid;
      border-radius: 0 0 25px 0;
    }

    #list-archive {
      border-radius: 0 0 0 25px;
    }

    .bg-list.active {
      #list-message {
        border-radius: 25px 25px 0 0;
        @include media-breakpoint-down(sm) {
          border-radius: 0 25px 0 0;
        }
      }

      #list-archive {
        border-radius: 25px 0 0 0;
      }
    }

    a, i {
      font-weight: bold;
    }
  }

  .btn-delete-mobile {
    display: none;
    z-index: 100;
    width: 100%;
    height: 50px;
    font-weight: 600;
    background-color: $white;
    color: $red;
    border-top: 1px solid $red;
    text-transform: uppercase;
    text-align: center;
    padding: 10px;

    @include media-breakpoint-down(sm) {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
    }
    @include media-breakpoint-up(xs) {
      border: 1px solid $red;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .message-api-body {
    height: 100%;
    border-radius: 0.9rem;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1500;
      border-radius: 0;
    }

    .chat-box-header {
      display: flex;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin: 0.5rem;
      @include media-breakpoint-down(sm) {
        &-part-emp {
          background-color: $inbox-part-emp;
        }
        &-presta {
          background-color: $inbox-presta;
        }
        &-pro {
          background-color: $inbox-pro;
        }
        box-shadow: 0 6px 6px #00000029;
        margin: 0;
        padding: 1rem 0.5rem;
      }

      a {
        color: $black;
        &:hover {
          text-decoration: none;
        }
      }

      &-content {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        @include media-breakpoint-down(sm) {
          line-height: normal;
        }

        .create-service-deal-button {
          margin-left: 1rem;
          margin-top: 1rem;
        }
      }

      &-upper {
        display: flex;
        justify-content: space-around;
      }

      &-image {
        margin-left: 1rem;

        img {
          width: 80px;
          @include media-breakpoint-down(sm) {
            width: 60px;
          }
        }
      }

      #close-chat {
        transform: translateY(50%);

        i {
          font-size: 25px;
        }
      }

      &-infos {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        margin: 0 1rem;

        @include media-breakpoint-down(md) {
          flex-direction: column;
        }

        &-name {
          font-size: 1.25rem;
          font-weight: bold;
        }

        &-rate {
          font-size: 12px;
          line-height: 14px;

          a {
            color: $electric-blue;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        &-contact {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }

      &-actions {
        cursor: pointer;
        div[data-toggle=dropdown] {
          font-size: 3rem;
          line-height: 3rem;
          color: $electric-blue;
          transform: rotate(90deg);
        }
      }
      &-mobile-infos-contact {
        margin: 0.5rem 0 0 1rem;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    .dropdown-menu {
      right: 0;
      left: unset !important;
      transform: translate3d(-25px, 20px, 0) !important;
    }

    .btn {
      text-transform: uppercase;
      color: black;
      font-weight: 500;
      border-radius: 20px;
      padding: 0.325rem 1.2rem;

      &:not(&-black-3) {
        font-size: small;
      }

      &-black-3 {
        text-transform: none;
        color: $white;
        background-color: black;
      }
      &-options {
        background-color: transparent;
        border: 1px solid black;
        &:hover {
          color: $white;
          background-color: black;
        }
      }
    }

    .btn, .alert, .state-img {
      &-green {
        background-color: $green;
      }

      &-orange {
        background-color: $orange;
      }

      &-red {
        background-color: $red;
      }
    }

    .text {
      &-green {
        color: $green;
      }

      &-orange {
        color: $orange;
      }

      &-red {
        color: $red;
      }
    }

    .state-border {
      margin: 0 0 1rem;
      border-radius: 0.9rem;

      &-green {
        border: 2px solid $green;
      }

      &-orange {
        border: 2px solid $orange;
      }

      &-red {
        border: 2px solid $red;
      }
    }

    .sticky-desktop {
      @include media-breakpoint-up(sm) {
        z-index: 1;
        position: sticky;
        top: 0.5rem;
      }
    }

    .state-img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 0.5rem;
    }

    .bg-mymessage {
      background-color: #D9E1FF;
    }

    .myaladom-inbox-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      bottom: 0;
      padding: 0 .8rem;
      @include media-breakpoint-up(md) {
        transform: translate(-50%);
        left: 50%;
        position: absolute;
        width: 90%;
      }

      @include media-breakpoint-down(sm) {
        position: fixed;
        z-index: 1;
        left: 0;
        padding-bottom: 1.2rem;
      }

      textarea {
        border-radius: 1.5rem !important;
        border: 2px solid $presta-color;

        &:disabled {
          border-color: rgba($presta-color, 0.2);
        }
      }

      .input-group-append {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 100;
      }

      i {
        color: $presta-color;
      }

      .create-service-deal-button {
        margin-bottom: 0.25rem;
      }
    }

    .myaladom-inbox-input {
      width: 100%;
      bottom: 0;
      padding: 0 .8rem;
      @include media-breakpoint-up(md) {
        transform: translate(-50%);
        left: 50%;
        position: absolute;
        width: 90%;
      }

      @include media-breakpoint-down(sm) {
        position: fixed;
        z-index: 1;
        left: 0;
        padding-bottom: 1.2rem;
      }

      .result-api-writing {
        display: none;
        font-size: 12px;
        color: $black;
      }
      #inbox-textarea {
        max-width: 90%;
        @include media-breakpoint-down(xs) {
          max-width: 85%;
        }
        border-radius: 1.5rem !important;
        border: 2px solid $presta-color;
        &:disabled {
          border-color: rgba($presta-color, 0.2);
        }
        resize: none;
        overflow: hidden;
      }
      .btn.btn-link {
        text-decoration: none;
        background-color: $off-white;
        &.add-api-message {
          background: transparent;
        }
        border-radius: 1.5rem;
        i {
          color: $presta-color;
          &:hover {
            font-size: larger;
          }
        }
      }
    }
  }

  #result-message-api {
    height: 700px;
    border-radius: 0.9rem;

    .chat-box-area {
      height: 100%;
      @include media-breakpoint-down(xs) {
        height: 95%;
      }
      overflow-y: auto;
      /* Hide scrollbar */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
      }
    }


    @include media-breakpoint-down(sm) {
      height: 78%;
      border-radius: 0;
    }
    @include media-breakpoint-up(md) {
      padding-bottom: 80px;
    }

    &.has-create-service-deal-button {
      padding-bottom: 60px;
      @include media-breakpoint-up(md) {
        padding-bottom: 155px;
      }
    }
  }

  .notif-email-block {
    @include media-breakpoint-down(sm) {
      height: unset;
      .form-text {
        display: none;
      }
    }
    height: 80px;
    margin-bottom: 0.5rem;

    .form-text {
      font-size: 0.75rem;
    }
  }

  .contact-list {
    height: 800px;

    .pagination {
      height: 90px;
    }

    &.has-pagination {
      .messages-box {
        height: 580px;
      }
    }
  }

  .messages-box {
    height: 670px;
    overflow-y: auto;
    /* Hide scrollbar */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }

  .list-group.no-result {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @include media-breakpoint-down(sm) {
      justify-content: unset;
      margin-top: 5rem;
    }
  }

  .message-api {
    position: relative;
    cursor: pointer;
    max-height: 200px;
    padding: 0.75rem 1.25rem;
    border: $list-group-border-width solid $list-group-border-color;
    border-radius: 0.9rem;
    margin: 10px 1.25rem 10px 0;

    &:not(.active) {
      &:hover {
        box-shadow: $default-inside-shadow;
      }
    }

    @include media-breakpoint-up(md) {
      &.active {
        margin-right: 0;
        border-radius: 0.9rem 0 0 0.9rem;
        padding-right: 2.5rem;

        .box-join {

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .contact-image, .message-api-checked-box {
      width: 50px;
      height: 50px;
    }

    .message-api-checked-box {
      display: none;
    }

    &.checked {

      .contact-image {
        display: none;
      }

      .message-api-checked-box {
        @extend .squared-picture;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $black;
        color: $black;
      }
    }

    .message-info-text {
      font-size: 0.75rem;
      line-height: 0.875rem;
    }

    .box-join {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 2.5rem;
      height: 100%;

      .btn-trash {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        color: $red;
        box-shadow: $default-shadow;
        width: 25px;
        height: 25px;
        border-radius: 50%;

        &:hover {
          box-shadow: $default-inside-shadow;
          text-decoration: none;
        }
      }
    }
  }
}

.service-deal-card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80%;
  margin: auto auto 4rem;
  padding: 1.5rem;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-radius: 9px;
  text-align: center;

  &.has-review-btn {
    margin-bottom: 2rem;
  }

  &-title {
    display: flex;
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;

    i {
      font-size: 2rem;
      margin-right: 1rem;
    }
  }

  &-body {
    font-weight: 500;
    text-align: center;
    margin: .5rem 0 1rem;
  }

  .service-deal-card-action-button {
    @extend .btn;
    color: $white;
    border: 2px solid;

    &:disabled {
      opacity: 1;
    }

    &:hover {
      background-color: $white;
    }
  }

  &.service-deal-card-success {
    border-color: $green;

    .service-deal-card-title {
      color: $green;
    }

    .service-deal-card-action-button {
      border-color: $green;

      &:not(:hover) {
        background-color: $green;
      }

      &:hover {
        color: $green;
      }
    }
  }

  &.service-deal-card-reject {
    border-color: $red;

    .service-deal-card-title {
      color: $red;
    }

    .service-deal-card-action-button {
      border-color: $red;

      &:not(:hover) {
        background-color: $red;
      }

      &:hover {
        color: $red;
      }
    }
  }

  &.service-deal-card-pending {
    border-color: $orange;

    .service-deal-card-title {
      color: $orange;
    }

    .service-deal-card-action-button {
      border-color: $orange;

      &:not(:hover) {
        background-color: $orange;
      }

      &:hover {
        color: $orange;
      }
    }
  }

  &.service-deal-card-finish {
    border-color: $electric-blue;

    .service-deal-card-title {
      color: $electric-blue;
    }

    .service-deal-card-action-button {
      border-color: $electric-blue;
      &:not(:hover) {
        background-color: $electric-blue;
        color: $white;
      }

      &:hover {
        color: $electric-blue;
      }
    }
  }
}

.service-deal-review-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $electric-blue !important;
  border-width: 2px;
  margin: auto auto 4rem;

  &:hover {
    color: $white !important;
  }
}

.unread-message, .to-validate-offers {
  position: absolute;
  bottom: calc(50% - 12px);
  right: 5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #FF6767;
  color: $white;
  text-align: center;
  padding-left: 1px;
  padding-top: 1px;
  @include media-breakpoint-down(md) {
    padding-top: 3px;
  }
  font-weight: bold;

  &.unread-top {
    bottom: unset;
    top: -10px;
    right: -5px;
  }
}

.real-unread-count, .real-to-validate-count {
  text-align: center;
}

.unread-contact-message {
  float: left;
  text-align: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  right: 15px;
  background-color: #f96634;
  color: $white;
  display: inline-block;
  margin-left: -18px;
  margin-top: -10px;
}

.ala-danger {
  color: $red;
}

.break-word {
  word-break: break-word;
  p {
    margin-bottom: 0;
  }
}

.ala-autoanswer {
  position: relative;
  padding: .75rem 2rem 1.5rem 1rem;
  &:after {
    content: url("/ui/static/img/ala_logo_small.png");
    position: absolute;
    bottom: 0;
    right: .75rem;
  }
}

div#archive-contact, div#unarchive-contact {
  cursor: pointer;
}

@include media-breakpoint-down(md) {
  .w-md-75 {
    width: 75% !important;
  }
}

#service-state-box, .message_masked {
  position: relative;
  .box {
    width: 55px;
    height: 50px;
    top: -5px;
    background-color: $white;
    img {
      top: 50%;
    }
  }
}

#service-state-box, #service-state-box .box, .message_masked, .message_masked .box {
  border: 1px solid #00000029;
  border-radius: 0.9rem;
}

// --------------------- Candidacies list -------------------------------------------//
#my-candidacy-list {
  .custom-list-item-card {
    min-height: unset;
  }
  #my-candidacy-figure{
    top: unset;
  }
}

#my-candidacy-form-toggle-btn {
  color: $white;
  background-color: black;
  border-radius: 500px;
  padding: .5rem 1.5rem;
  font-weight: bold;
  text-transform: uppercase;

  i {
    font-size: 1.25rem;
  }

  &.active {
    color: black;
    background-color: $white;
    border: 2px solid;
  }
}

#my-candidacy-form {
  display: none;
  max-width: 465px;
  background-color: $gray-100;
  border-radius: 19px;
  padding: 1.5rem;

  legend {
    font-size: 1rem;
    font-weight: bold;
  }
}

.candidacies-list-title {
  font-size: 2rem;
  font-weight: bold;
  color: #4a9aef;
  text-align: center;
  margin-bottom: 15px;
}

.candidacy-card {
  width: 100%;
  margin: 0 auto 1rem auto;
  @include media-breakpoint-up(lg) {
    width: 80%
  }
  .description-col {
    margin: auto;
    padding-right: 0;
    @include media-breakpoint-up(lg) {
      padding-left: 45px;
    }
  }
  .right-center-btn {
    margin: auto;
    text-align: right;
    padding-left: 0;
    padding-right: 40px;
    @include media-breakpoint-up(lg) {
      padding-right: 70px;
    }
    .btn-candidacy {
      border-radius: 0.7rem !important;
      color: white !important;
      font-weight: 600;
      &.btn-azure {
        text-transform: unset;
      }
    }
    .btn-dl {
      border: 1px solid black;
      color: black !important;
      @include media-breakpoint-up(md) {
        display: none;
      }
      @media (max-width:539px) {
        margin-top: 10px;
      }
    }
  }
}

// ATS mails templates
//////////////////////

#myaladom-candidacy-mails-templates {

  .ats-mails-templates-title-block {
    margin: 2%;
  }

  .ats-mails-templates-list, .ats-sms-templates-list {

    margin: 10px 5%;

    &-item {
      background-color:$gray-100;
      width: 100%;
      border-radius: 15px;
      margin: 15px 0;
      padding: 10px 24px;
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        font-size: 16px;
        color: $black;
        font-weight:600;
      }

    }

    &-add{
      margin-top: 20px;
    }
  }

  a.btn {
    color: $white;
    background-color: $black;
    border-radius: 10px;
    padding: 8px 24px;

    i {
      font-size: 18px;
    }
  }
}

#myaladom-candidacy-mails-templates-form {
  .myaladom-ats-form-container {
    background-color: $gray-100;
    border-radius: 15px;
    padding: 34px 10% 50px;

    @include media-breakpoint-down(lg) {
      padding-right: 10px;
      padding-left: 10px;
    }

    input[name$=-DELETE] {
      display: none;
    }
  }
}

.myaladom-ats-form-container {

  input, select, .tox-tinymce, .custom-file-label, .custom-file {
    max-width: 100% !important;
    margin-top: 1rem;

    &.custom-file-input {
      margin-top: 0;
    }
  }

  fieldset.form-group {
    margin: 10px 0;
  }

  .custom-file-label, .custom-file {
    margin-top: 0;
  }

  .custom-file-label::after {
    height: 100%;
  }

  button[type=submit] {
    padding: .375rem .75rem;
    max-width: unset !important;
  }
  .btn {
    border-radius: 500px !important;
    font-size: 15px;
    margin: 5px;
    &.btn-attachement{
      background-color: $white;
      color: $black;
      box-shadow: $default-shadow;

      i {
        font-weight: bold;
      }

      &.disabled {
        background-color: $gray-800;
        color: $gray-200;
      }
    }
    &.btn-submit {
      background-color: $electric-blue;
      color: $white;
      width: 150px;
    }
    &.btn-cancel {
      background-color: $black;
      color: $white;
      width: 150px;
    }
  }

  .helper {
    font-size: 12px;
  }

  .file-input {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }

  span.file-attachement-badge {
    border-radius: 500px;
    padding: 5px 10px;
    margin-right: 5px;
    background-color: $electric-blue;
    color: $white;
    margin-bottom: .5rem;
    display: inline-block;
  }

  .errors-list span {
    font-size: 12px;
    color: $red;
  }
}

.part-emp {
  color: $cyan;
  font-weight: 700;
}

.presta {
  color: $electric-blue;
  font-weight: 700;
  @media (max-width: 991px) {
    margin-top: 24px;
  }
}

#circle-part-emp {
  background-color: $cyan;
  left: 0;
  &:not(.circle-select) {
    transform: translate(15%, 12%);
  }
}

#circle-presta {
  background-color: $electric-blue;
  right: 0;
  &:not(.circle-select) {
    transform: translate(-15%, 12%);
  }
}

.circle-base {
  position: absolute;
  z-index: 10;
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0 3px 14px 0 #bdbdbb;
  cursor: pointer;
}

.circle-select {
  width: 50px;
  height: 50px;
  border: 5px solid white;
  transform: translateY(-25%);
}

.hr-select {
  border-top: 1px solid black !important;
}

.hr-hide {
  border-top: 1px solid $off-white;
}

.text-base {
  color: #ada7a7;
}

.text-select {
  color: black;
}

#btn-part-type {
  border-radius: 20px;
  &:hover {
    background-color: black !important;
  }
}

.backbar {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 31px;
  border-radius: 20px;
  box-shadow: 0 3px 14px 0 #bdbdbb;
}

.opening_day {
  color: $black;
  font-weight: 500;
  @include media-breakpoint-down(md) {
    box-shadow : $default-shadow;
    border: none;
    border-radius: 20px;
    margin-bottom: 2rem;
  }
}

.btn {
  &.btn-part-emp-2 {
    color: $white;
    background-color: $cyan;
    box-shadow: $default-shadow;
    border-radius: 15px;

    &:hover {
      background-color: white;
      color: $cyan !important;
      border-color: $cyan;
      border-width: 1px;
      box-shadow: none;
    }
  }
}
// --------- List pro job and service offers ----------------------- //

.job-group-choices {
  padding: .5rem;
  h3 {
    color: $black;
  }
  .custom-checkbox {
    margin-left: 0;
  }
}
.job-state-choices,.job-subcategory-choices,.job-contract-choices,.job-time-choices,.job-source-choices, .job-agency-choices {
  margin: 0 .5rem;
  .card-header {
    border: none;
    border-radius: 20px!important;
    button {
      font-weight: 600;
    }
  }
}

// --------- List particular (and freelance) job and service offers ----------------------- //

.refresh-search-help {
  font: normal normal 400 12px/12px Montserrat;
}

#status {
  .legend-secondary {
    color: #000;
    margin-left: 3rem;
  }

  legend {
    float: left;
    width: auto;
    padding-right: 3rem;
    &.legend-secondary:before {
      content: url("/ui/static/img/service_offer/croix.svg");
    }
  }
  .form-check{
    margin-top: 3rem;

  }
}
#service-offer-deactivate-form {
  #reason, #status, #alert{
    display: None;
  }


  [id^=validate_form] {
    margin-left: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  legend {
    color: #F75C5E;
    font: normal normal 600 15px/32px Montserrat;
    margin-left:3rem;

  }

  .custom-control-label {
    font: normal normal 600 15px/32px Montserrat;
    margin-left: 3rem;
    font-weight: 600;
  }

  .description {
    font: normal normal 300 14px/32px Montserrat;
  }

  .custom-control {
    legend {
      color:black;
    }
    label[for^=id_employer_type_], label[for^=id_contract_type_]{
      color: #7b8797;
      margin:0px;
      padding:0px;
      &:before, &:after {
        border-radius:0px;
      }
    }
  }
}

#message_thanks {
  margin-left:2rem;

  .text_blue {
    font: normal normal 600 14px/32px Montserrat;
    color: #2653FB;
    margin-top:0px;
    margin-bottom:0px;
  }
  .text_bold {
    color: black;
    font: normal normal 600 14px/32px Montserrat;
    margin-top:0px;
    margin-bottom:0px;
  }
}

#service-offer-price {
  display: none;
  border: none;
  padding-top: 2rem;
  .card-body {
    background-color: #f8f9fa;
    border-radius: 20px;
  }
}

#comment {
  color:gray;
}

.offer-card {
  background-color: #F8F9FA !important;
  border-radius: 20px !important;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm) {
    box-shadow: $default-shadow;
  }
  .expired-border {
    border: 3px solid red;
    border-radius: 0 20px 20px 0;
    @include media-breakpoint-down(md) {
      border-radius: 0 20px 0 0;
    }
    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }
  .black-link-icon {
    font-size: 1.1rem;
    color: black;
    margin-left: 10px;
    &:hover {
      text-decoration: none;
      color: black;
    }
  }

  .badge-offer {
    padding: 0.35rem;
    margin-right: 5px;
    border-radius: 50%;
  }

  .offer-bottom {
    position: absolute;
    bottom: 0;
  }
  .offer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    @include media-breakpoint-down(xs) {
      justify-content: unset;
    }
    margin-bottom: 15px;

    .toggle {
      --width: 60px;
      --height: calc(var(--width) / 3);
      position: relative;
      display: inline-block;
      width: var(--width);
      height: var(--height);
      border-radius: var(--height);
      cursor: pointer;
      margin-left:2rem;
    }

    .toggle input {
      display: none;
    }

    .toggle .slider {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--height);
      background-color:#51e584 ;
      transition: all 0.4s ease-in-out;
    }

    .toggle .slider::before {
      content: '';
      left:0px;
      bottom:0px;
      position: absolute;
      width: calc(var(--height));
      height: calc(var(--height));
      border-radius: calc(var(--height) / 2);
      background-color: #f4f5f6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      transition: all 0.4s ease-in-out;
    }

    .toggle input:checked+.slider {
      background-color: #f75c5e;
    }

    .toggle input:disabled+.slider {
       cursor: not-allowed;
       opacity: .5;
    }
    .toggle input:checked+.slider::before {
      transform: translateX(calc(var(--width) - var(--height)));
    }
  }
  .col-description {
    border-right: 1px solid rgba(0,24,53,.125);
    @include media-breakpoint-down(md) {
      order: 3;
      border-top: 1px solid rgba(0,24,53,.125);
      border-right: none;
    }
    .row-buttons {
      gap: 10px;
    }
    .btn-white {
      border: 1px solid black;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .col-info {
    border-right: 1px solid rgba(0,24,53,.125);
    @include media-breakpoint-down(md) {
      order: 1;
    }
    @include media-breakpoint-down(xs) {
      border-right: none;
    }
    i {
      font-size: 1.5rem;
      width: auto;
    }
  }
  .col-edit {
    @include media-breakpoint-down(md) {
      order: 2;
    }
  }
  .col-boost {
    display:flex;
    justify-content: right;
    @include media-breakpoint-down(xs) {
      justify-content: unset;
    }
  }
  .col-payment-tag {
    .badge-offer-payment {
      color: black;
      display: block;
    }
  }

  .info-mobile {
    float: right;
    @include media-breakpoint-down(sm) {
      float: left;
    }
  }
  .btn-mobile {
    border-radius: 25px !important;
  }
  .thin-btn {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .center-mobile {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}

// --------------------- Particular Job Offers ------------------------------------------- //
.job-offer-container {
  background-color: #F8F9FA !important;
  border-radius: 20px !important;
  padding: 20px;
  @include media-breakpoint-up(sm) {
    padding: 50px 50px 50px;
  }
}

.job-offer-card {
  .active-border {
    border: 3px solid #51e584;
    border-radius: 0 20px 20px 0;
    @include media-breakpoint-down(md) {
      border-radius: 0 20px 0 0;
    }
    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }
  .badge-offer {
    color: $cyan;
  }
}

.myaladom-box-shadow {
  box-shadow: $default-shadow;
}

// --------------------- List service offers ------------------------------------------- //
.service-offer-card {
  .active-border {
    border: 3px solid #51e584;
    border-radius: 0 20px 20px 0;
    @include media-breakpoint-down(md) {
      border-radius: 0 20px 0 0;
    }
    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }
  .card-body {
    padding: 0;
  }
  .badge-offer {
    color: $electric-blue;
  }
  .btn-testimony {
    text-transform: unset;
  }
}
.offer-moderate {
  font-size: .8rem;
}

// --------------------- Options ------------------------------------------- //
#prime-offer, #visibility-offer {
  @include media-breakpoint-down(md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.prime-job-images {
  @include media-breakpoint-down(md) {
    order: 0;
  }
}
.job-offer-payment-box {
  @include media-breakpoint-down(md) {
    order: 1;
  }
}
.job-offer-option-box {
  width: 50%;
  color: #001835;
  border: 3px solid #001835;
  border-radius: 15px;
  margin: auto;
  max-width: 700px;
  width: 100%;
  padding: 1rem;
  @include media-breakpoint-down(md) {
    order: 2;
  }

  p {
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
      margin-left: 80px;
    }
  }

  span {
    font-size: 1.1rem;
    margin-left: 15px;
  }
}

#review_presta_freelance_list {

  p {
    color: $black;
    font-weight: 300;
    font-size: 14px;
    line-height: 1rem;

    @include media-breakpoint-down(md) {
      font-size: 10px;
    }
  }

  .avg_rate_block {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;

    &_title, &_rate {
      color: $electric-blue;
      font-weight: 800;
    }

    &_title {
      font-size: 30px;

      @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 25px;
      }
    }

    &_rate {
      font-size: 50px;
      @include media-breakpoint-down(md) {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }

  .review_presta_freelance_list_block {
    color: $black;
    margin-right: 5rem;
    margin-left: 5rem;

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-left: 0;
      font-size: 10px;
    }

    .review-filter-block {
      font-size: 16px;
      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
      font-weight: 500;
    }
  }
}

#review-pro-list {
  .avg-rate-block {
    width: 100%;
    padding: 5% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    background-color: $electric-blue;
    border-radius: 10px;

    .rating-stars {
      max-width: 450px;
    }

    &-rate {
      font-size: 3.125rem;
      font-weight: 800;
    }

    &-nb-review {
      font-size: 1.25rem;
      font-weight: 800;

      a {
        color: $white;

        &:hover { text-decoration: none; }
      }
    }

    @include media-breakpoint-down(md) {
      .rating-stars {
        max-width: 250px;
      }

      &-rate {
        font-size: 2.25rem;
      }

      &-nb-review {
        font-size: 1rem;
      }
    }
  }

  .review-request-block, .review-widget-block {
    background-color: $gray-100;
    border-radius: 10px;
    height: 100%;
    font-size: 1.125rem !important;
    color: $black;

    @include media-breakpoint-down(md) {
      font-size: 1rem !important;
    }

    @include media-breakpoint-down(sm) {
      padding: 2rem;
    }
  }

  .review-request-block {
    border: 5px solid $electric-blue;
    border-radius: 10px;

    &-title {
      font-weight: 300;
    }

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;

      label {
        font-size: 1.125rem !important;
        font-weight: 500;
      }

      #id_services {
        width: 100%;
      }

      .select2-container {
        max-width: 260px;
      }

    }
  }

  .review-widget-block {
    &-widget {
      display: flex;
      justify-content: center;
    }

    h3 {
      color: $black;
      font-size: 1.125rem;
      font-weight: bold;
    }

    p {
      font-weight: 300;
    }
  }

  .review-sort-block {
    display: flex;
    justify-content: space-between;
    color: $black;
    margin: 1rem;
    font-size: 1.25rem;
    font-weight: 500;

    @include media-breakpoint-down(sm) {
      margin: 1rem 0;
    }

    i {
      font-size: 26px;
    }
    &-button-group {
      display: flex;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin: 1.5rem 0;
        align-items: center;
      }

      .review-sort-block-button {
        width: 130px;
        height: 40px;
        margin-left: 15px;
        margin-right: 15px;
        box-shadow: $default-shadow;
        border: none;
        border-radius: 500px;
        color: $black;
        text-align: center;
        font-size: 15px;
        font-weight: normal;
        line-height: 40px;

        @include media-breakpoint-down(sm) {
          margin-top: 1rem;
          width: 80%;
        }

        &.active {
          color: $white;
          background-color: $electric-blue;
        }
      }
    }

    .review-sort-orientation-button {
      height: 26px;
      transform: scaleY(1);
      transition: transform 300ms linear;

      &.reverse {
        transform: scaleY(-1);
      }
    }
  }
}

#review-pro-list, #review_presta_freelance_list {

  hr {
    border-top: 5px solid $black;
    margin: 1rem;
  }

  .review-list-loading {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.swal2-icon {
  border: none !important;
}

.modal-backdrop {
    opacity:0.7 !important;
}

.prime-job-image-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 7px;
  border : 1px solid $black;
  @include media-breakpoint-down(md) {
    width: 70px;
    height: 70px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.visibility-offer-images {
  h3 {
    color: $electric-blue;
    font-weight: 500;
  }
}

// compte/mes-disponibilités
form#member-edit-availability-form {
  fieldset.form-group {
    &:nth-child(3), &:nth-child(4) {
      display: none;
    }
  }
}


// Informations bancaire
.stripe-account-card {
  @include media-breakpoint-down(sm) {
  padding: 2rem 1rem !important;
  }

  &-title {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1;
  }

  &-content {
    line-height: 1.4;
    &-info {
      &:hover, &.show {
        .dropdown-menu {
          display: block;
        }
      }
    }
    span {
      &:hover {
        color: $electric-blue;
      }
      #stripe-account-card-content-info-logo {
      cursor: pointer;
      font-weight: 900;
    }

      .dropdown-menu {
        display: none;
      }
    }
  }
  .stripe-account-card-action {
    p {
      line-height: 1.2;
    }
  }
  .btn-success {
    border: none;
    &:hover {
      background-color: black;
      color: #51e584;
    }
  }
}
.popover-stripe {
   padding: 1rem;
   box-shadow: 0 4px 6px rgba(0,0,0,.1607843137);
   border-radius: 15px!important;
   .popover-body {
     padding: 1rem;
     color: black;
     font-size: .875rem;
   }
}

.credit-card {
  background: transparent linear-gradient(114deg, #0F09A3 0%, #2653FC 100%) 0 0 no-repeat padding-box;
  box-shadow: 20px 20px 20px #00000033;
  border-radius: 33px;
  max-width: 700px;
  height: auto;
  padding: 2rem 3rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-down(md) {
    max-width: 500px;
  }
  @include media-breakpoint-down(sm) {
    max-width: unset;
    padding: 1rem 2rem;
  }
  .magnetic-tape {
    background: black;
    height: 50px;
    margin: 0 -3rem;
    @include media-breakpoint-down(sm) {
      margin: 0 -2rem;
      border-radius: 12px 12px 0 0;
    }
  }
  .back-aladom-logo {
    width: 50%;
    margin-top: 2rem;
    @include media-breakpoint-down(md) {
      width: 30%;
    }
    @include media-breakpoint-down(sm) {
      width: 40%;
    }
  }
  .credit-card-info {
    height: 10px;
    border-radius: 7px;
    margin-bottom: 1rem;
    &.has-info {
      color: $white;
    }
    @include media-breakpoint-down(md) {
      height: 20px;
    }
    &.bank-info {
      width: 70px;
      height: 50px;
      &:not(.has-info) {
        background: $white;
      }
      @include media-breakpoint-down(md) {
        width: 40px;
        height: 20px;
      }
    }
    &.credit-card-logo {
      width: 20%;
      height: auto;
      float: right;
      img {
        margin-top: -2rem;
        @include media-breakpoint-down(xs) {
          margin-top: -1rem;
        }
      }
      @include media-breakpoint-down(xs) {
        width: 30%;
      }
    }
    &.credit-card-puce {
      width: 70px;
      height: auto;
      @include media-breakpoint-down(md) {
        width: 40px;
      }
    }
    &.number-info {
      width: 100%;
      position: relative;
      margin-top: 4rem;
      text-align: center;
      &:not(.has-info) {
        background: $white;
      }
      &.has-info {
        font-size: 2rem;
        @include media-breakpoint-down(sm) {
          font-size: 1.4rem;
        }
        @include media-breakpoint-down(xs) {
          font-size: 1rem;
        }
      }
      @include media-breakpoint-down(lg) {
        margin-top: 3rem;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 2rem;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 3rem;
      }
      @include media-breakpoint-down(xs) {
        margin-top: 0;
      }
    }
    &.expiration-date-info {
      position: relative;
      margin-left: 40%;
      width: 20%;
      &:not(.has-info) {
        background: $white;
      }
      &.has-info {
        @include media-breakpoint-down(sm) {
          width: 40%;
          margin-left: 20%;
        }
        @include media-breakpoint-down(xs) {
          width: 60%;
          margin-left: 10%;
        }
      }
    }
    &.credit-card-type-info {
      width: 20%;
      position: relative;
      float: right;
      height: 10px;
      margin-top: 4rem;
      &:not(.has-info) {
        background: $white;
      }
      @include media-breakpoint-down(md) {
        margin-top: -1rem;
      }
      @include media-breakpoint-down(sm) {
        margin-top: -1rem;
        height: 30px;
      }
      img {
        @include media-breakpoint-down(xs) {
          margin-top: 1rem;
        }
      }
    }
    &.name-info {
      width: 60%;
      position: relative;
      margin-top: 5rem;
      &:not(.has-info) {
        background: $white;
        opacity: 0.3;
      }
      &.has-info {
        text-align: left !important;
        @include media-breakpoint-down(xs) {
          width: 80%;
        }
      }
      @include media-breakpoint-down(md) {
        margin-top: 2rem;
      }
      @include media-breakpoint-down(xs) {
        margin-top: 0;
      }
    }
  }
}

.btn-credit-card {
  margin-top: 2rem;
  background: $white;
  border: 2px solid #2653FC !important;
  border-radius: 24px !important;
  color: #4269FC !important;
  letter-spacing: 0;
  @include media-breakpoint-down(md) {
    padding: .3rem 7rem !important;
  }
  @include media-breakpoint-down(sm) {
    padding: .3rem 4rem !important;
  }
  .label-btn-credit-card {
    @include media-breakpoint-down(md) {
      font: normal normal 600 15px/25px Montserrat;
    }
  }
}

.btn-very-dark {
  background: #000000;
}

body.myaladom-stats {
  color: black;

  h2 {
    color: white;
    text-transform: none;
    font-weight: normal;
    text-align: left;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
  h3, h4, h5, h6 {
    color: black;
    &.title-underlined{
      position: relative;
      padding-bottom: .8rem;
      margin-bottom: 1.5rem;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 800;
      &:after {
        position: absolute;
        content: "";
        height: 2px;
        background-color: black;
        width: 125%;
        bottom: 0;
        left: 0;
        max-width: 50vw;
      }
    }
  }

  .stats-contact {
    background-color: #F7F7F7;
    color: $black;
    line-height: 1.3;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 1rem 1.4rem;
    margin-bottom: 1rem;
    font-weight: 300;
    border-radius: 10px;

    i {
      padding-left: 1.4rem;
      font-size: 1.7rem;
      color: #707070;
      padding-bottom: .2rem;
    }
    p {
      margin: 0;
    }
    .stats-contact-hidden {
      width: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition-property: opacity;
      transition-duration: 500ms;
    }
    .stats-contact-shown {
      width: 100%;
      height: auto;
      opacity: 1;
      transition-property: opacity;
      transition-duration: 500ms;
    }
  }

  .accordion.stats-accordion {
    background-color: #dfe5ff;
    border-radius: 20px;
    color: $black;

    .btn.btn-black {
      position: relative;
      &:not(.collapsed) .accordion-icon {
        transform: rotate(180deg);
      }
      .accordion-icon {
        -webkit-transition: transform 400ms ease-in-out;
        -moz-transition: transform 400ms ease-in-out;
        -o-transition: transform 400ms ease-in-out;
        position: absolute;
        right: 20px;
        top: 0;
        font-size: 1.3rem;
        height: 100%;
        display: flex;
        align-items: center;
        color: white;
      }
      &:hover {
        background-color: $black !important;
      }
    }

    .card {
      border: none;
      background-color: transparent;
    }
  }
  .conversion-card-label {
    font-size: .8rem;
    font-weight: 500;
    color: black;
  }
  .conversion-card {
    border: 1px solid $electric-blue;
    border-radius: 10px;
    display: flex;
    color: black;
    font-size: 1.2rem;
    font-weight: 500;
    padding: .5rem 1rem;
    margin-top: .4rem;
    flex-wrap: wrap;
    i {
      color: $electric-blue;
      font-size: 1.5rem;
      margin-right: 1rem;
      margin-top: .2rem;
    }
  }

  .leaflet-main-container {
    max-width: 100%;
    border-radius: 25px;
    overflow: hidden;
  }

  .form-control.reportrange-text {
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    box-shadow: none;
    border: 2px solid black;
    color: black;
    background-color: transparent;
  }

  .leaflet-top, .leaflet-bottom, .leaflet-pane, .leaflet-tile-pane {
    z-index: 0;
  }

  .vue-daterange-picker {
    width: auto;
    max-width: 260px;
    margin-right: .5rem;
  }

  .pagination {
    padding: 0;
  }
  .stats-multiselect-filters {
    display: inline-flex;
    border: 2px solid black;
    color: black;
    padding: 0 1rem;
    border-radius: 20px;
    align-items: center;
    @include media-breakpoint-down(sm) {
      display: block;
      padding: .5rem 1.5rem;
    }
    .stats-multiselect-filters-head {
      display: inline-flex;
      align-items: center;
      i {
        font-size: 1.2rem;
      }
    }
    .stats-multiselect-filters-body {
      display: inline-flex;
      flex-wrap: wrap;
    }
    .stats-multiselect-title {
      text-transform: uppercase;
      color: black;
      font-weight: 800;
      padding: 0 5px;
    }
  }
  .stats-multiselect-filter {
    padding: 5px;
  }
  .multiselect {
    width: auto;
    display: inline-block;
    min-height: auto;
  }
  .multiselect__content-wrapper {
    width: 100%;
  }
  .multiselect, .multiselect__input, .multiselect__single {
    font-size: 13px;
  }
  .multiselect__select, .multiselect__select::before {
    display: none;
  }
  .multiselect__placeholder, .multiselect__tags, .multiselect__input, .multiselect__input::placeholder,
  .multiselect__option {
    color: black;
    font-size: .8rem;
    margin-bottom: 0;
  }
  .multiselect__placeholder {
    padding-top: 0;
  }
  .multiselect.multiselect--active .multiselect__tags {
    padding-right: 40px;
  }
  .multiselect__tags {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    min-height: auto;
  }
  .multiselect__tags-wrap {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .multiselect__tag {
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .multiselect__tag, .multiselect__option--highlight, .multiselect__option--highlight::after {
    background: black;
    color: white;
  }
  .multiselect__tag-icon::after {
    color: white;
  }
  .multiselect__spinner::after, .multiselect__spinner::before {
    border-top-color: $electric-blue;
  }
  .multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background-color: $electric-blue;
  }

  .table.b-table {
    border-spacing: 0;
    padding-top: 15px;

    tr.table-dark {
      background-color: black;
      color: white;
      font-weight: 600;
      td, td.total-table-col {
        background-color: black;
        color: white;
        border: none;
      }
    }

    tr.table-primary {
      background-color: $electric-blue;
      color: white;
      td:not(.total-table-col) {
        background-color: $electric-blue;
        color: white;
        border-bottom: none;
        border-top: none;
      }
    }

    th, td {
      border: 1px solid #3c415a;
      padding: .25rem 1rem;
    }
    th.total-table-col {
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 15px;
        position: absolute;
        top: -15px;
        background-color: #3c415a;
        left: -1px;
      }
    }
    td.total-table-col:last-child {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 15px;
        position: absolute;
        top: -15px;
        background-color: #3c415a;
        left: 0;
      }
    }
    th.total-table-col, td.total-table-col {
      background-color: #3c415a;
      color: white;
    }
    th {
      background-color: #3c415a;
      color: white;
      text-align: center;

      &.table-first-col {
        background-color: black;
        padding: 0 !important;
        select {
          border-radius: 0;
          height: 100%;
        }
      }
    }
    th.hide-data-form {
      background-color: #dfe5ff;
      border: none;

      &:before {
        content: '';
        width: 100%;
        height: 15px;
        position: absolute;
        top: -15px;
        background-color: #dfe5ff;
        left: 0;
      }
    }
    td {
      color: black;
      &:first-child{
        border-left: none;
      }
      &:last-child{
        border-right: none;
      }
      &:not(:first-child) {
        text-align: center;
      }
    }
    td.table-bold {
      font-weight: 600;
    }
    tr:last-child td {
      border-bottom: none;
    }
    tbody > tr > .table-b-table-default {
      background-color: #dfe5ff;
    }
  }
}

// Helps
////////
body.myaladom .page-help {
  .title-help-home {
    color: $electric-blue !important;
    font-size: 3rem;
    font-weight: 700;
  }
  .welcome-title {
    color: black;
    font-weight: 700;
    font-size: 2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }
  h1 {
    text-transform: none;
    font-size: 2rem !important;
    font-weight: 800;
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }
  p {
    color: black;
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 1.7rem;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  .aladom-autocomplete {
    margin: 0 11rem;
    @include media-breakpoint-down(md) {
      margin: 0;
    }
    .aladom-autocomplete-search-input {
      width: 100%;
      padding: 1rem 3.5rem;
    }
  }
  .button-iframe {
    text-align: left;
    .btn-testimony {
      border-radius: 40px;
    }
  }
  .help-header-image {
    width: 15%;
    @include media-breakpoint-down(xs) {
     width: 25%;
    }
  }
  .mb-description {
    margin-bottom: 7rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
  #breadcrumb {
    display: flex;
    justify-content: center;
    margin-top: 0 !important;
  }
  i {
    color: $electric-blue;
  }
  .breadcrumb {
    font-size: 1.5rem;
    font-weight: 200;
    padding-top: 0;
    background-color: unset;
    @include media-breakpoint-down(sm) {
      font-size: 1.4rem;
    }
    @include media-breakpoint-down(xs) {
      font-size: 0.8rem;
    }
    .breadcrumb-item:not(.active) {
      a {
        color: $electric-blue;

        &:hover {
          color: black;
          text-decoration: none;
        }
      }
    }
    .breadcrumb-item {
      color:black;
    }
  }

  .help-feature {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 450px;
    color: black;
    cursor: pointer;
    transition: box-shadow 500ms;
    box-shadow: $default-shadow, inset 0 0 0px #00000029;
    border: 1px solid $electric-blue;
    margin: auto;
    height: 100%;
    padding: 2rem;
    @include media-breakpoint-down(md) {
      min-height: 140px;
      margin-top: 1rem;
    }
    @include media-breakpoint-down(sm) {
      height: fit-content;
    }
    &:hover {
      color: black;
      text-decoration: none;
      box-shadow: 0 0 0px #00000029, $default-inside-shadow;
    }
    img {
      width: 40%;
      @include media-breakpoint-down(sm) {
        width: 20%;
      }
    }
    h2 {
      margin-bottom: 1rem;
      margin-top: 1rem;
      text-transform: none;
      font-weight: 800;
    }
    p {
      font-size: .7rem;
      margin-bottom: 0;
    }

    &-text {
      display: none;
      @include media-breakpoint-up(md) {
        display: flex;
      }
    }
  }
  .help-items-card {
    border-radius: 20px;
    background-color: white;
    text-align: left;
    padding: 3rem;
    border: 1px solid grey;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
    .icon_img {
      width: 8%;
      @include media-breakpoint-down(sm) {
        width: 15%;
      }
    }
    &-title, h2 {
      font-size: 1.5rem;
      font-weight: 800;
      color: black;
      @include media-breakpoint-down(sm) {
        font-size: 1.1rem;
      }
    }
    h3 {
      color: black;
    }
    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.6rem;
      margin-bottom: 4rem;
    }
    a {
      font-size: 1rem;
      color: #91919b;
      font-weight: 600;
      transition: .1s;
      &:hover {
        color: black;
        border-left: 8px solid $electric-blue;
        text-decoration: none;
      }
      @include media-breakpoint-down(sm) {
        font-size: .8rem;
        margin-bottom: .3rem;
      }
    }
    &-summary {
      padding: 1rem;
    }
    .questions-title {
      font-size: 1rem;
      font-weight: 800;
      &-summary {
        font-weight: 600;
      }
    }
    .answer-content {
      border-left: 6px solid $electric-blue;
      a {
        font-size: 1rem;
        color: $electric-blue;
        font-weight: 600;
        transition: .1s;
        &:hover {
          color: black;
          border-bottom: 3px solid $electric-blue;
          border-left: none;
        }
      }
    }
  }
}

.page-tabs-menu {
  display: inline-flex;
  width: fit-content;
  margin-bottom: 2rem;
  background-color: $white;
  border-radius: 50px;
  box-shadow: $default-shadow;

  @include media-breakpoint-down(sm) {
    font-size: .70rem;
  }

  &-link {
    height: 100%;
    display: flex;
    padding: .5rem 1rem;
    color: $black;
    align-items:center;
    justify-content:center;
    text-align: center;

    &:hover {
      text-decoration: none;
      color: $gray-600;
    }

    &.active {
      color: $white;
      background-color: black;
      border-radius: 500px;
    }
  }
}


// My Job Search Dashboard
//////////////////////////
.my-job-search-dashboard-card {
  width: 100%;
  padding: 2rem;
  color: $black;
  background-color: $white;
  border-radius: 19px;

  &-feature {
    padding: 2rem;

    &-title {
      font-size: 1.8rem;
      font-weight: 900;
    }

    &-infos {
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 1rem 0;

      &-value {
        font-size: 2rem;
        font-weight: 900;
        margin-right: 1rem;
      }
    }

    &-link {
      text-decoration: underline;
    }

    .subcategories_list>button {
      width: 48px;
      padding: 0.75rem;
    }
  }

  &-form, &-form-more {
    width: 100%;
    height: 100%;
    padding: 3rem;
    background-color: $gray-100;
    box-shadow: $default-inside-shadow;
    border-radius: 19px;

    @include media-breakpoint-down(sm) {
      padding: 2rem 1rem;
    }

    fieldset.form-group {
      margin-top: 0;
    }

    &-submit.btn[type=submit] {
      padding: $btn-padding-y $btn-padding-x;
      display: none;
    }
  }

  &-form {
    position: relative;
    overflow: hidden;
    border: 1px solid $black;

    legend, label.form-control-label {
      font-weight: bold;
    }
  }

  &-form-more {
    &-big-title, &-title, &-help {
      color: $black;
    }

    &-big-title, &-title {
      font-weight: bold;
    }

    &-big-title {
      font-size: 1.25rem;
    }

    &-title {
      font-size: 1rem;
    }

    &-help {
      font-size: .75rem;
    }
  }
}

// Job alerts edit
//////////////////

#job-offer-alert-form {

  p {
    color: $black;
  }

  .category-search {
    padding: 1rem;
    border-radius: 19px;
    box-shadow: $default-shadow;
  }

  .city-search, .subcategory-search {
    padding: 1rem 1rem 3rem;

    legend {
      font-size: 1.25rem !important;
      font-weight: bold !important;
      color: $black !important;
      text-transform: capitalize !important;
      margin: 0 !important;
    }
  }

  .city-search, .home-text-search {
    box-shadow: none !important;
    border-radius: unset !important;
    background: unset !important;

    .position-relative i {
      position: absolute;
      left: 5px;
      font-size: 1.5rem;
      top: calc(50% - 12px);
    }
  }

  .city-search {
    input[type=text], input[type=text]::placeholder {
      color: $light-red;
    }
  }

  .home-text-search {
    input[type=text], input[type=text]::placeholder, .position-relative i {
      color: $testimony-blue;
    }
  }
}
// Pro Tunnel of completed profile
//////////////////////////////////
.pro-tunnel {
  margin-bottom: 4rem;
  @include media-breakpoint-up(md) {
    .pro-tunnel-container {
      padding: 0 7rem;
      &.no-padding {
        padding: 0;
      }
    }
    #tunnel-step-seven-form .pro-tunnel-container {
      padding: 0;
    }
  }
  label.form-control-label, legend {
    text-transform: uppercase;
    font: normal normal bold 12px/15px Montserrat;
  }
  .pro-tunnel-header {
    &.no-modal-header {
      display: none !important;
    }
    background: #001837;
    display: block !important;
    h2 {
      margin-bottom: 0;
      text-align: left;
      color: $testimony-blue !important;
      &.align-right {
        text-align: right;
      }
    }
    h3.step-counter {
      text-align: left;
      color: $white;
      font-size: 1rem;
      &.align-right {
        text-align: right;
      }
    }
  }
  div.pro-step {
    &.no-modal-header {
      display: none !important;
    }
    margin-top: -25px;
    div.col-4 {
      div {
        background: white;
        border-radius: 50px;
        border: 1px solid $testimony-blue;
        width: 50px;
        height: 50px;
        margin: auto;
        padding: 8px;
      }
    }
    i {
      font-size: 30px;
      color: $testimony-blue;
    }
  }

  .pro-tunnel-label {
    text-align: left;
    margin: 1rem 0;
    font: normal normal 900 30px/37px Montserrat;
    color: #000000;
    text-transform: uppercase;
  }
  .pro-tunnel-sub-label {
    text-align: left;
    margin: 1rem 0;
    font: normal normal 900 20px/24px Montserrat;
    color: #000000;
    text-transform: uppercase;
  }
  select.form-control {
    width: 100%;
  }
  .list-item-card.list-item-offer {
    @include media-breakpoint-down(md) {
      min-height: 200px;
    }
  }

  // Picture form
  i.ala-arrow-right {
    font-size: 100px;
  }
  .picture-preview {
    background: #FFFFFF;
    box-shadow: 0 3px 6px #00000029;
    text-align: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto 1rem;
    figure {
      border-radius: 50%;
      background-color: #fff;
      overflow: hidden;
      width: 120px;
      height: 120px;
      display: flex;
      position: relative;
      flex-shrink: 0;
      margin: auto;
      box-shadow: 0 3px 6px #00000029;
    }
    .real-picture-preview {
      width: 100%;
    }
  }
  .preview {
    font: italic normal bold 20px/24px Montserrat;
    letter-spacing: 0;
    color: #000000;
  }
  .preview-paragraphs {
    font: italic normal normal 14px/18px Montserrat;
    letter-spacing: 0;
    color: #000000;
  }
  .custom-file-label {
    background: #F5F5F5;
    border-radius: 7px;
    font: normal normal normal 14px/18px Montserrat;
    letter-spacing: 0;
    color: #000000;
  }
  .custom-file-label:after {
    content: "Importer";
    background: #F5F5F5;
    border: 1px solid #000000;
    border-radius: 7px;
  }


  // Service offer
  .delete-job-offer {
    background: #F75C5E 0 0 no-repeat padding-box;
    border-radius: 30px;
    padding: .5rem;
    border: unset;
  }
  .subcategory-multi-results {
    button.card {
      padding: 1rem;
      i:not(.ala-close) {
        margin: auto;
      }
    }
  }
  button.category-select {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  div.category-label {
    button {
      i {
        margin-right: 0 !important;
        width: 25px !important;
      }
    }
  }
  .resume-offers {
    background: #F5F5F5;
    border: 2px solid #2653FB;
    &.pending-updated {
      border: 2px solid $red;
    }
    border-radius: 7px;
    padding: .5rem;
    margin-bottom: .5rem;
    .resume-title {
      font: normal normal bold 14px/18px Montserrat;
      letter-spacing: 0;
      color: #000000;
    }
  }
  .resume-subcategory {
    background: #FFFFFF;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 2px;
    i {
      width: 100%;
    }
  }

  .modal-body button.city-select span {
    font-size: unset;
  }
  .certifications-form {
    .form-group label.form-control-label {
      font-weight: 600;
      font-size: .75rem;
    }
    @include media-breakpoint-up(md) {
      label[for=id_is_qualisap] {
        margin-bottom: 1rem;
      }
    }
  }
}

// Opening hours
#field-opening_hours {
  div.opening_day.container {
    &:nth-child(2n+1) { //impair
      background: #E8EDFF;
    }
    &:nth-child(2n) { //pair
      background: #BDCCFE;
    }
    span.px-2 {
      padding: 0 .5rem !important;
    }
    .pb-3 {
      padding-bottom: 0!important;
    }
    .py-3 {
      padding: .5rem 0 !important;
    }
    input[type=time] {
      border: 1px solid #000000;
      border-radius: 25px;
      background: transparent;
    }
    .btn.btn-black {
      font-size: 0.8rem;
      font-weight: 400;
      border: 1px solid #000000;
      border-radius: 25px;
      letter-spacing: 0;
      padding: 0.5rem 1rem;
    }
    .rdv {
      display: none;
      margin: auto;
      &.apptvalidated {
        display: inline;
      }
    }
    .hours {
      display: flex;
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
      .hours-am, .hours-pm, .am, .pm {
        display: flex;
      }
    }
  }
}

.finish-title {
  text-align: left;
  font: normal normal bold 40px/49px Montserrat;
  letter-spacing: 0;
  color: #2653FC;
}
p.finish-paragraphs {
  margin-top: 2rem;
  text-align: left;
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0;
  color: #000000;
}

form#member-set-password-form {
  label {
    font-size: .75rem;
  }
}
.previous-step {
  text-align: left;
  margin: 1rem 0 1rem 3rem;
  a {
    text-decoration: none;
    color: $black !important;
    &:hover {
      color: $black !important;
    }
  }
}

// Checks-page //
#checks-page {
  .card {
    border-radius: 20px;
  }
  .card-header {
    border-radius: 20px 20px 0 0;
  }
  .btn-verified {
    &:hover {
      border: 1px solid #25DE65;
      background-color: transparent;
    }
  }
}

#pro-member-site-info-form {
  .copy-podcast-iframe {
    margin-left: 1rem;
    border-radius: 25px;
    color: $white;
    background-color: $black;
    text-decoration: none;
    border: none;
    padding: .5rem 2rem;
  }
  .podcast-group {
    .toast {
      color: $black;
      background-color: $white;
      display: inline-block;
      margin-left: 1rem;
    }
  }
  .podcast-disclaimer {
    .podcast-logo {
      width: 200px;
      height: 200px;
      box-shadow: 0 0 20px rgba(255,255,255);
      @include media-breakpoint-down(md) {
        width: 150px;
        height: 150px;
      }
    }
    h3 {
      color: white;
      @include media-breakpoint-down(lg) {
        margin-top: 1rem;
      }
    }
    .btn-white-border {
      &:hover {
        color: $pink-podcast;
      }
    }
  }
  .custom-control-inline.inline-btn label {
    font-weight: 400;
    color: #fff;
    letter-spacing: 0;
    box-shadow: unset;
    text-transform: none;
    cursor: pointer;
    padding: .3rem;
    border-radius: 15px;
  }
  .block-pro-member-site-info {
    border-radius: 20px;
    padding: 2rem;
    margin-bottom: 2rem;
  }
  .opening_day {
    max-width: none;
    border-radius: 20px;
    margin-bottom: 1rem;
    #myaladom-main-container {
      .opening-hour {
        @include media-breakpoint-down(xl) {
          display: unset;
        }
      }
    }
  }
  div#id_news-FORMS_CONTAINER small.text-muted {
    display: block;
  }
}

// After in form input
//////////////////////
form {
  // the class (ex: euro-after) need to be the last one for this selector to work properly.
  div[class$="-after"]::after {
    position: absolute;
    color: $black;
    top: 55%;
    left: 210px;
  }

  .euro-after:not(.has-danger)::after {
    content: "€"
  }

  .percentage-after:not(.has-danger)::after {
    content: "%"
  }
}
.opinion-system-btn {
  color: $white;
  background-color: #004f9f;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  line-height: inherit;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px;
}

.result-os-reviews {
  margin: 1rem 1rem 0 2rem;
}

/// Tunnel leads -  parameters
/////////////////
#tunnel-parameters {
  .col-sm:first-child {
    border-radius: 500px 0 0 500px;
  }
  .col-sm:last-child {
    border-radius: 0 500px 500px 0;
  }
  @include media-breakpoint-down(md) {
    .col-sm:first-child, .col-sm:last-child {
      border-radius: 0;
    }
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    padding: 5px 10px;
    border-radius: 20px;
    border: none;
  }
}
#leads-config-step2-form {
  .btn-warning {
    color: black;
    &:hover {
      background-color: transparent;
      border: 1px solid $warning;
      text-decoration: none;
    }
    &:focus {
      background-color: transparent;
      border: 1px solid $warning;
      text-decoration: none;
      box-shadow: none;
    }
  }
  .form-group {
    margin: 0;
  }
  textarea #cityCodesList .custom-select, .form-control, .tox-tinymce {
    box-shadow: none;
    border: 1px solid black;
  }
}
#leads-config-step3-form {
  .col-md-4 {
    font-weight: 300;
    img.leads-config-subcat {
      border: .3rem solid white;
      border-radius: 20px;
      cursor: pointer;
      &:hover {
        box-shadow:0 5px 6px rgba(0,0,0,.3019607843), inset 0 0 0 transparent;
      }
      &.selected {
        border-color: $azure-blue;
      }
    }
  }
}
#leads-config-step4-form {
  .box {
    background: white;
    border-radius: 30px;
    .euro-after::after {
      top: 10%;
      left: 290px;
    }
  }
  h3 {
    font-weight: 800;
  }
}

#result-chatbot {
  .user-message {
    font-weight: bold;
  }
}

.chatgpt-loading {
  opacity: 0.2;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
.spinner-loading {
  background: url("/ui/static/img/ala-loading.gif") center / 10% no-repeat;
  z-index: 10000;
  @include media-breakpoint-down(md) {
    background: url("/ui/static/img/ala-loading.gif") center / 30% no-repeat;
    position: relative;
    height: 1000px;
  }
}

form.job-offer {
  label[for=id_wage_type] {
    white-space: nowrap;
  }
  input#id_wage {
    height: 36px;
  }
  select#id_contract_duration_type {
    @include media-breakpoint-up(md) {
      margin-top: 2rem;
      margin-left: 1rem;
    }
  }
  div.form-group {
    span.icon-end, i.icon-end {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 1rem;
      color: black;
    }
  }
  fieldset legend, label.form-control-label {
    font-weight: 600;
  }
}

div.btn-chatgpt-modal {
  margin-top: -1rem;
  display: flex;
  justify-content: flex-end;
}
button.show-chatgpt-modal {
  margin-bottom: 1rem;
  display: inline;
  margin-left: 1rem;
  margin-top: 1rem;
  position: relative;
  width: 115px;
  height: 35px;
  background-color: #000;
  align-items: center;
  color: $white;
  flex-direction: column;
  justify-content: center;
  border: none;
  padding: 9px;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: .7rem;
  &:hover {
    color: $black;
    background: $white;
  }
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    left: -4px;
    top: -1px;
    margin: auto;
    width: 123px;
    height: 42px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100% );
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100% );
    transform: translate3d(0, 0, 0) scale(0.85);
    filter: blur(20px);
  }
  &:hover::after {
    filter: blur(30px);
  }
  &:hover::before {
    transform: rotate(-180deg);
  }
  &:active::before {
    scale: 0.7;
  }
}
div.chatgpt-example {
  text-align: center;
  margin-top: 4rem;
  width: 100%;
  height: 80px;
  padding: 1rem;
  @include media-breakpoint-down(md) {
    height: 100px;
  }
}
div.chatgpt-modal {
  position: absolute;
  min-height: 100px;
  width: 520px;
  z-index: 1000;
  background: rgb(37, 37, 37);
  color: $white;
  border-radius: 25px;
  box-shadow: rgba(15, 15, 15, 0.1) 0 0 0 1px, rgba(15, 15, 15, 0.2) 0 3px 6px, rgba(15, 15, 15, 0.4) 0px 9px 24px;
  .pagination {
    padding: 0;
    display: unset;
  }
  @include media-breakpoint-down(xs) {
    width: 300px;
  }
  div.chatgpt-header {
    position: absolute !important;
    display: inline-flex;
    button.hide-chatgpt {
      border-radius: 50px;
      background: $white;
      border: unset;
      height: 20px;
      margin-left: .5rem;
      margin-top: .5rem;
      width: 20px;
      padding: -.5rem;
      font-size: 0.7rem;
      color: rgb(37, 37, 37);
      padding: 0;
    }
  }
  div.answer {
    font-weight: bold;
    color: cadetblue;
  }
  div.chatgpt-body {
    display: block;
    width: 100%;
    min-height: 200px;
    margin-top: 4rem;
    padding: .5rem 1rem 1rem;
    max-height: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow-y: auto;
  }
  button.send-chatgpt-content {
    --b: 3px;
    --s: .45em;
    --color: white;
    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
      var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-align: center;
    margin: -2.5rem auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover, &:focus-visible {
      --_p: 0px;
      outline-color: var(--color);
      outline-offset: .05em;
    }
    &:active {
      background: var(--color);
      color: #fff;
    }
    @include media-breakpoint-down(md) {
      font-size: .8rem;
    }
  }
  button.job-chatgpt-button {
    background: transparent;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-top: .6rem;
    border: 2px solid $white;
  }
  input.chatgpt-input {
    background: gray;
    color: $white !important;
    max-width: unset;
    width: 410px;
    margin: 1rem 1rem 1rem .5rem;
    @include media-breakpoint-down(xs) {
      max-width: 190px;
    }
  }
  i.ala-paper-plane {
    color: $white;
    margin-top: 30px;
  }
}

div.training-request-details, div.quote-request-details {
  ul li {
    list-style-type: none;
  }
}
.btn-filter-training-request {
  margin-top: 2rem;
  border-radius: 50px !important;
  color: $black !important;
  background: $white;
  width: 250px;
  max-width: 250px !important;
  min-height: 50px;
  text-align: center;
  border: 2px solid $black !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: unset !important;
  }
  &.btn-testimony {
    border: unset !important;
    color: $white !important;
  }
  &::placeholder {
    text-align: center;
    color: $black !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
}
.filter-training-request-group {
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    white-space: break-spaces !important;
  }
  input.select2-search__field {
    width: 100% !important;
    &::placeholder {
      text-align: center;
      color: $black !important;
      font-size: 1rem !important;
      font-weight: 500 !important;
    }
  }
}

div.total-training-info-and-report {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2rem;
  padding-top: 1.5rem;
  color: $black;
}
#training-info-request-list {
  border-radius: 25px 25px 0 0;
  thead {
    background: $black;
    color: $white;
    th {
      border: unset;
    }
  }
  tbody {
    td, th {
      color: $black;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.jobboards-card {
  padding: 3rem 2rem;
  background: $white;
  border-radius: 1.25rem;

  &-items, hr {
    width: 100%;
  }

  &-items {
    display: grid;
    grid-template-columns: [first] 120px [line2] auto [line3] 50px [col4-start] 60px  [col5-start] 50px [end];
    column-gap: 1rem;
    align-items: center;
    color: $black;

    &-logo {
      @include media-breakpoint-down(lg) {
        display: none;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }

    &-name {
      @include media-breakpoint-down(lg) {
        justify-self: start;
      }
      justify-self: end;
      font-size: 1rem;
      font-weight: 500;
    }

    &-icon, &-action, &-more {
      justify-self: center;
    }

    &-icon {
      font-size: 1.5rem;
    }

    &-more-content {
      padding: 1rem 2rem;
      color: $black;

      .form-check {
        margin-bottom: 1rem ;
        input[type='checkbox'] {
          height: 1.25rem;
          width: 1.25rem;
        }
        label {
          margin-left: 1rem;
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .huclink-infos {
        line-height: 1.75rem;
        i.ala-infos {
          font-size: 1.75rem;
          color: $gray-600;
        }
      }
    }
  }
}

/// Leads config page
/////////////////////

#leads_config {
  .leads_stand_by_card {
    &_header {
      display: grid;
      grid-template-columns: 2fr 1fr 3fr;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      [data-toggle]:not(.collapsed) {
        display: none;
      }

      .badge {
        font-size: 0.75rem;
        font-weight: normal;
        padding: .5rem .75rem;
      }
    }

    &_help {
      display: flex;
      align-items: center;
      background-color: $gray-200;
      border-radius: 4px;
      width: fit-content;

      i {
        font-size: 1.25rem;
        margin-right: 1rem;
      }
    }
  }
}